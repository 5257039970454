// @flow
import React from 'react'

import type { Node } from 'react'

type PropsT = {
  children: Node,
  className: string,
}

const Header = ({ children, className }: PropsT) => (
  <h4 className={className}>{children}</h4>
)

Header.defaultProps = {
  className: '',
}

export default Header
