// @flow
import React from 'react'
import styles from './BackButton.module.scss'
import colors from '@thanx/uikit/scss/variables/_colors.scss'
import { Link } from '@reach/router'
import { heapTrack } from 'utilities/analytics'

type PropsT = {
  className: string,
  to: string,
  trackingProperties?: {},
}

const defaultProps = {
  className: '',
}

/*
 * Fine print wrapper.
 */
const BackButton = ({ className, to, trackingProperties }: PropsT) => (
  <div className="position-relative">
    <Link
      to={to}
      className={`py-xs px-s ${className} ${styles.backButton}`}
      onClick={() => {
        heapTrack('Button Click', { Button: 'Back', ...trackingProperties })
      }}
    >
      <svg
        width="7"
        height="12"
        viewBox="0 0 7 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 1.4L5.67568 0L0 6L5.67568 12L7 10.6L2.64865 6L7 1.4Z"
          fill={colors.grey90}
        />
      </svg>
    </Link>
  </div>
)

BackButton.defaultProps = defaultProps

export default BackButton
