// @flow
import React from 'react'
import { Translate } from 'react-i18nify'
import Header from 'components/Signup/Header'
import IconCircle from 'components/IconCircle'
import Layout from 'components/Signup/Layout'
import useTheme from 'hooks/useTheme'
import SmsImage from './SmsImage'

const Confirmation = () => {
  const { colors } = useTheme()

  return (
    <Layout open>
      <Header>
        <Translate value="pages.confirmation.title" />
      </Header>

      <Translate
        value={`pages.confirmation.subtitle`}
        tag="p"
        className="grey-70-text body-text-2 mb-xl"
      />

      <IconCircle
        animate
        className="mx-auto"
        color={colors.primary50}
        size="156px"
      >
        <SmsImage color={colors.primary} />
      </IconCircle>
    </Layout>
  )
}

export default Confirmation
