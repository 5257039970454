// @flow
import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { Translate } from 'react-i18nify'
import IconCircle from 'components/IconCircle'
import Header from 'components/Signup/Header'
import Layout from 'components/Signup/Layout'
import { useSignupData } from 'components/Signup/SignupData'
import useTheme from 'hooks/useTheme'

import type { NavigateFn } from '@reach/router'
type Props = {
  navigate?: NavigateFn,
}

const AllSet = ({ navigate }: Props) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      if (navigate) navigate('../choose')
    }, 3000)
    return () => clearTimeout(timer)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const merchant = useSignupData().signupData.merchant
  const { colors } = useTheme()

  return (
    <Layout open>
      <Header>
        <Translate value="pages.allSet.title" />
      </Header>

      <Translate
        value="pages.allSet.subtitle"
        name={merchant.name}
        tag="p"
        className="grey-70-text body-text-2 mb-xl"
      />

      <IconCircle
        animate
        className="mx-auto"
        color={colors.primary50}
        size="144px"
      >
        <FontAwesomeIcon
          icon={faCheck}
          size="3x"
          style={{ color: colors.primary }}
        />
      </IconCircle>
    </Layout>
  )
}

export default AllSet
