// @flow
import React from 'react'
import { Button } from '@thanx/uikit/button'
import { Spacer } from '@thanx/uikit/spacer'
import Header from 'components/Signup/Header'
import { Translate } from 'react-i18nify'
import { Link } from '@reach/router'
import { LockIcon } from 'assets/images/lockIcon.svg.js'
import Layout from 'components/Signup/Layout'
import useTheme from 'hooks/useTheme'
import RewardBox from 'components/RewardBox'
import idx from 'idx'
import { useEnrollCardProgram } from 'utilities/program'

const TrackProgressBonus = () => {
  const { colors } = useTheme()
  const offerProgram = useEnrollCardProgram()

  const redeemText =
    idx(offerProgram, _ => _.redeem.description) ||
    'pages.trackProgressBonus.redeemText'

  return (
    <Layout>
      <Header className="mb-0 px-s pb-xs">
        <Translate
          value="pages.trackProgressBonus.title"
          className="body-text-3 font-weight-bold"
        />
        <Translate
          value="pages.trackProgressBonus.title2"
          className="body-text-3 font-weight-normal"
        />
      </Header>
      <Spacer size="l" />
      <RewardBox animate className="mx-auto w-100">
        <LockIcon color={colors.primary} />
        <Translate value={redeemText} className="body-text-3 d-block" />
      </RewardBox>
      <Spacer size="s" />
      <Link to={'../credit-card'} className="text-decoration-none">
        <Button className="d-flex flex-column w-100 py-s mb-s">
          <Translate
            value="pages.trackProgressBonus.primaryBtn.main"
            className="body-text-3 d-block"
          />
        </Button>
      </Link>

      <Link to="../receipts" className="text-decoration-none">
        <Button className="d-flex flex-column w-100" kind="minimal_secondary">
          <Translate
            value="pages.trackProgressBonus.secondaryBtn"
            className="body-text-3 d-block"
          />
        </Button>
      </Link>
    </Layout>
  )
}

export default TrackProgressBonus
