// @flow
import { useEffect } from 'react'
import isNil from 'lodash/isNil'
import { useApolloClient } from 'react-apollo-hooks'
import { useSignupData } from 'components/Signup/SignupData'
import { PROGRAM_QUERY } from 'components/Signup/queries'

import type { Node } from 'react'
import type { NavigateFn } from '@reach/router'
import type { ProgramQuery } from 'components/Signup/__generated__/ProgramQuery'

type PropsT = {
  children: Node,
  merchantHandle?: string,
  navigate?: NavigateFn,
  programIdentifier?: string,
}

const Program = ({
  children,
  merchantHandle,
  navigate,
  programIdentifier,
}: PropsT) => {
  const { signupData, setSignupData } = useSignupData()
  const client = useApolloClient()

  useEffect(() => {
    async function fetchProgram() {
      try {
        const {
          data,
          errors,
        }: { data: ProgramQuery, errors: Object } = await client.query({
          query: PROGRAM_QUERY,
          variables: {
            merchantHandle: merchantHandle,
            identifier: programIdentifier,
          },
        })

        if (
          errors ||
          !data.merchant ||
          !data.merchant.program ||
          !data.merchant.program.redeem
        ) {
          if (navigate)
            navigate('../join', {
              // $FlowFixMe
              state: { headerI18n: 'pages.welcome.title.unknown' },
            })
          return
        }

        const program = data.merchant.program
        setSignupData(signupData => ({
          ...signupData,
          program,
        }))
      } catch (response) {
        if (navigate)
          navigate('../join', {
            // $FlowFixMe
            state: { headerI18n: 'pages.welcome.title.unknown' },
          })
        return
      }
    }

    if (isNil(programIdentifier)) {
      return
    }

    if (!signupData.program) {
      fetchProgram()
    }
    /* eslint-disable react-hooks/exhaustive-deps */
    // navigate doesn't maintain reference equality between renders
  }, [
    client,
    merchantHandle,
    programIdentifier,
    setSignupData,
    signupData.program,
  ])
  /* eslint-enable react-hooks/exhaustive-deps */

  return children
}

export default Program
