// @flow
import React from 'react'
import styles from './IconCircle.module.scss'

type PropsT = {
  animate: boolean,
  children: React$Node,
  className: string,
  color: string,
  size: string,
}

const defaultProps = {
  animate: false,
  className: '',
}

/**
 * A filled circle with an icon in the center.
 * Can be optionally animated by settings animate true.
 */

const IconCircle = (props: PropsT) => (
  <div
    className={`
      rounded-circle d-flex justify-content-center align-items-center
      ${props.className}
    `}
    style={{
      backgroundColor: props.color,
      width: props.size,
      height: props.size,
    }}
  >
    <div className={props.animate ? styles.animated : ''}>{props.children}</div>
  </div>
)

IconCircle.defaultProps = defaultProps

export default IconCircle
