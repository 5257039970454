// @flow
import { gql } from 'apollo-boost'
import { USER_FRAGMENT } from 'components/Signup/queries'

export const SIGN_UP_NEW_USER = gql`
  mutation signUpNewUser(
    $email: String!
    $programId: ID!
    $uid: String
    $referrerId: ID
    $signupCode: String
  ) {
    signUpNewUser(
      email: $email
      programId: $programId
      uid: $uid
      referrerId: $referrerId
      signupCode: $signupCode
    ) {
      user {
        ...UserFields
      }
      token
      errors {
        status
      }
    }
  }
  ${USER_FRAGMENT}
`

export const SignupError = {
  EMAIL_INVALID: 'email_invalid',
  SMS_USER_NOT_FOUND: 'sms_user_not_found',
  USER_EXISTS: 'user_exists',
}
