// @flow
import { gql } from 'apollo-boost'
import type { textSelf_textSelf } from './__generated__/textSelf'

export const TEXT_SELF_MUTATION = gql`
  mutation textSelf($phone: String!, $merchantId: ID!) {
    textSelf(phone: $phone, merchantId: $merchantId) {
      void
    }
  }
`

export type TEXT_SELF_MUTATION_T = {
  data: { textSelf: textSelf_textSelf },
  message: ?string,
  networkError: ?string,
}
