// @flow
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLifeRing } from '@fortawesome/free-regular-svg-icons'
import isNil from 'lodash/isNil'
import queryString from 'query-string'
import { I18n, Translate } from 'react-i18nify'
import { heapTrack } from 'utilities/analytics'

type PropsT = {
  className: string,
  appId: ?number | ?string,
  userId: ?number | ?string,
  trackingProperties?: {},
}

const defaultProps = {
  className: '',
}

/*
 * Contact us link. Usually included at the bottom of a page.
 */
const ContactSupport = ({
  appId,
  className,
  userId,
  trackingProperties,
}: PropsT) => {
  const userString = isNil(userId) ? 'unknown-user' : `user-${userId}`
  const subject = isNil(appId)
    ? I18n.t('contactSupport.subject')
    : I18n.t('contactSupport.subjectApp', { appId })

  const paramsString = queryString.stringify({ subject })
  const mailtoLink = `mailto: support+${userString}@thanx.com?${paramsString}`

  return (
    <div className={className}>
      <a
        className="body-text-5 grey-50-text text-decoration-none grey-50-hover"
        href={mailtoLink}
        onClick={() => {
          heapTrack('Button Click', {
            Button: 'Contact Support',
            ...trackingProperties,
          })
        }}
      >
        <FontAwesomeIcon className="mr-xxs" icon={faLifeRing} size="lg" />
        <Translate value="contactSupport.linkText" />
      </a>
    </div>
  )
}

ContactSupport.defaultProps = defaultProps

export default ContactSupport
