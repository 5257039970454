// @flow
import React from 'react'

type PropsT = {
  error: string,
}

const ServerSideError = ({ error }: PropsT) => {
  return (
    <p className="mt-n3 text-left text-danger body-text-4 pt-xxs">{error}</p>
  )
}

export default ServerSideError
