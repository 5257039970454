// @flow
import React, { useState } from 'react'
import Formsy from 'formsy-react'
import idx from 'idx'
import { Button } from '@thanx/uikit/button'
import PhoneInput from 'components/PhoneInput'
import { I18n } from 'react-i18nify'
import { KIND } from 'baseui/notification'
import Label from 'components/Label'
import { TEXT_SELF_MUTATION } from './mutations'
import { useMutation } from 'react-apollo-hooks'
import { useSignupData } from 'components/Signup/SignupData'
import Notification from 'components/Signup/Notification'
import isEmpty from 'lodash/isEmpty'
import { getDigits } from 'utilities/formHelpers'

const Web = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [errors, setErrors] = useState(null)

  const { signupData } = useSignupData()
  const textSelf = useMutation(TEXT_SELF_MUTATION)
  const submit = async form => {
    setIsLoading(true)
    try {
      const { errors } = await textSelf({
        variables: {
          ...form,
          merchantId: signupData.merchant.id,
        },
      })

      setIsLoading(false)

      if (!isEmpty(errors)) {
        setErrors(I18n.t('pages.app.webError'))
        return
      }

      setIsSuccess(true)
    } catch (_) {
      setIsLoading(false)
      setErrors(I18n.t('pages.app.webError'))
    }
  }

  const errorBanner = errors ? (
    <Notification kind={KIND.negative}>{errors}</Notification>
  ) : null

  return (
    <>
      {errorBanner}
      <Formsy
        className="text-left mb-s"
        mapping={inputs => ({
          ...inputs,
          phone: getDigits(inputs.phone || ''),
        })}
        onValidSubmit={submit}
        onSubmit={() => setIsLoading(true)}
        onInvalidSubmit={() => setIsLoading(false)}
      >
        <PhoneInput
          placeholder="555-555-5555"
          label={<Label title={I18n.t('pages.info.form.phoneNumber.label')} />}
          name="phone"
          className="mb-s"
          value={idx(signupData, _ => _.user.phone) || ''}
        />
        <Button
          className="w-100"
          isSuccess={isSuccess}
          isLoading={isLoading}
          type="submit"
        >
          {I18n.t('pages.app.getAppWeb')}
        </Button>
      </Formsy>
    </>
  )
}

export default Web
