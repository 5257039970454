// @flow
import React from 'react'
import { Notification as BUINotification } from 'baseui/notification'

const Notification = (props: Object) => (
  <BUINotification
    overrides={{
      Body: { style: { width: 'auto' } },
    }}
    {...props}
  />
)

export default Notification
