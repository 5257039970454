// @flow
import React, { useState } from 'react'
import idx from 'idx'
import Header from 'components/Signup/Header'
import { Translate, I18n } from 'react-i18nify'
import Layout from 'components/Signup/Layout'
import { Spacer } from '@thanx/uikit/spacer'
import BackButton from 'components/Signup/BackButton'
import ContactSupport from 'components/Signup/ContactSupport'
import { useSignupData } from 'components/Signup/SignupData'
import { KIND } from 'baseui/notification'
import { SMS_SUBSCRIBE_MUTATION } from './mutations'
import { useMutation } from 'react-apollo-hooks'
import Formsy from 'formsy-react'
import { Button } from '@thanx/uikit/button'
import PhoneInput from 'components/PhoneInput'
import { Link } from '@reach/router'
import Label from 'components/Label'
import Notification from 'components/Signup/Notification'
import { getDigits } from 'utilities/formHelpers'
import isEmpty from 'lodash/isEmpty'
import useTheme from 'hooks/useTheme'

const Sms = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [errors, setErrors] = useState(null)

  const { colors } = useTheme()

  const {
    signupData: { merchant, program, user },
  } = useSignupData()
  const smsSubscribe = useMutation(SMS_SUBSCRIBE_MUTATION)

  const userId = idx(user, _ => _.id)
  const appId = merchant.app && merchant.isBranded ? merchant.app.id : null

  const submit = async form => {
    if (!program) return

    setIsLoading(true)
    try {
      const { errors } = await smsSubscribe({
        variables: {
          ...form,
          merchantId: merchant.id,
          redeemDescription: idx(program, _ => _.redeem.description),
        },
      })

      setIsLoading(false)

      if (!isEmpty(errors)) {
        setErrors(I18n.t('pages.app.webError'))
        return
      }

      setIsSuccess(true)
    } catch (_) {
      setIsLoading(false)
      setErrors(I18n.t('pages.app.webError'))
    }
  }

  const errorBanner = errors ? (
    <Notification kind={KIND.negative}>{errors}</Notification>
  ) : null

  return (
    <Layout open>
      <BackButton to="../choose" />
      <Spacer size="s" />
      <Header className="mb-0">
        <Translate value="pages.sms.title" tag="p" />

        <Translate
          value="pages.sms.subtitle"
          tag="p"
          className="px-xs mb-s body-text-3 grey-70-text normal"
        />
      </Header>

      <Spacer size="l" />
      {errorBanner}
      <Formsy
        className="text-left mb-l"
        mapping={inputs => ({
          ...inputs,
          phone: getDigits(inputs.phone || ''),
        })}
        onValidSubmit={submit}
      >
        <PhoneInput
          placeholder="555-555-5555"
          label={<Label title={I18n.t('pages.info.form.phoneNumber.label')} />}
          name="phone"
          className="mb-s"
          value={idx(user, _ => _.phone) || ''}
        />

        <Button
          className="w-100"
          isSuccess={isSuccess}
          isLoading={isLoading}
          type="submit"
        >
          {I18n.t('pages.sms.submitButton')}
        </Button>
      </Formsy>

      <Link to="../app" style={{ color: colors.primary, paddingBottom: 40 }}>
        {I18n.t('pages.sms.downloadButton')}
      </Link>

      <Translate
        merchantName={merchant.name}
        value="pages.sms.smsLegal_1"
        tag="p"
        className="px-xs mb-s body-text-6 grey-70-text normal"
      />
      <Translate
        value="pages.sms.smsLegal_2"
        tag="p"
        className="px-xs mb-m body-text-6 grey-70-text normal"
      />

      <ContactSupport appId={appId} userId={userId} />
    </Layout>
  )
}

export default Sms
