// @flow
import React, { useState, useEffect } from 'react'
import { Button } from '@thanx/uikit/button'
import { FormsyInput } from '@thanx/uikit/input'
import { FormsySelect } from '@thanx/uikit/select'
import { Spacer } from '@thanx/uikit/spacer'
import { KIND } from 'baseui/notification'
import Formsy from 'formsy-react'
import Header from 'components/Signup/Header'
import idx from 'idx'
import identity from 'lodash/identity'
import isEmpty from 'lodash/isEmpty'
import pickBy from 'lodash/pickBy'
import { useMutation } from 'react-apollo-hooks'
import { I18n, Translate } from 'react-i18nify'
import Label from 'components/Label'
import Layout from 'components/Signup/Layout'
import Notification from 'components/Signup/Notification'
import PhoneInput from 'components/PhoneInput'
import { useSignupData } from 'components/Signup/SignupData'
import { RewardBlock, RewardContainer } from 'components/Signup/reward'
import { getDigits } from 'utilities/formHelpers'
import { useEnrollCardProgram } from 'utilities/program'
import { UPDATE_USER_MUTATION } from './mutations'
import styles from './Info.module.scss'
import Switch from 'components/Switch'

import type { NavigateFn } from '@reach/router'
import type { updateUser as updateUserT } from './__generated__/updateUser'

type Props = {
  navigate?: NavigateFn,
  location?: Object,
}
type UpdateUserResponseT = {| data: updateUserT |} | {| errors: Object |}

const Info = ({ navigate, location }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [transition, setTransition] = useState(false)
  const [errors, setErrors] = useState(null)
  const updateUser = useMutation(UPDATE_USER_MUTATION)
  const { signupData, setSignupData } = useSignupData()
  const offerProgram = useEnrollCardProgram()

  const percent = idx(signupData, _ => _.program.redeem.percent)
  const description = idx(offerProgram, _ => _.redeem.description) || ''
  const errorBanner = errors ? (
    <Notification kind={KIND.negative}>{errors}</Notification>
  ) : null
  const fromCreditCard = idx(location, _ => _.state.fromCreditCard)

  useEffect(() => {
    const timer = setTimeout(
      () => {
        setTransition(true)
      },
      fromCreditCard ? 1500 : 0
    )
    return () => clearTimeout(timer)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let timer
    if (isSuccess) {
      timer = setTimeout(() => {
        if (navigate) navigate('../all-set')
        setTimeout(() => setIsSuccess(false), 50)
      }, 750)
    }
    return () => clearTimeout(timer)
  }, [isSuccess, setIsSuccess]) // eslint-disable-line react-hooks/exhaustive-deps

  const submit = async form => {
    setIsLoading(true)
    // don't submit phone number if it is empty
    const cleanedForm = pickBy(form, identity)
    try {
      const response: UpdateUserResponseT = await updateUser({
        variables: {
          ...cleanedForm,
          merchantId: signupData.merchant.id,
          state: 'registered',
        },
      })
      setIsLoading(false)
      if (response.errors) {
        setErrors(I18n.t('pages.info.form.errors.generic'))
        return
      }

      const user = idx(response, _ => _.data.updateUser.user)
      if (user) {
        setSignupData(state => ({
          ...state,
          user: {
            ...state.user,
            ...user,
          },
        }))
      }
      setIsSuccess(true)
    } catch (response) {
      let error = I18n.t('pages.info.form.errors.generic')
      const message = idx(response, _ => _.message) || ''
      if (message.includes('Phone has already been taken'))
        error = I18n.t('pages.info.form.errors.phone')

      setIsLoading(false)
      setErrors(error)
    }
  }

  let phoneInput = null
  if (!idx(signupData, _ => _.user.isSms)) {
    phoneInput = (
      <PhoneInput
        description={I18n.t('pages.info.form.phoneNumber.legal')}
        placeholder={I18n.t('pages.info.form.phoneNumber.placeholder')}
        label={<Label title={I18n.t('pages.info.form.phoneNumber.label')} />}
        name="phone"
        className="mb-s"
      />
    )
  }

  let favoriteLocationSelect = null
  const locations = idx(signupData, _ => _.merchant.locations)
  if (!isEmpty(locations)) {
    const options = locations
      .sort((firstLocation, secondLocation) =>
        firstLocation.tagValue > secondLocation.tagValue ? 1 : -1
      )
      .map(location => {
        const name = `${location.tagValue}`
        return {
          label: location.isComingSoon ? `*${name}` : name,
          value: name,
        }
      })

    favoriteLocationSelect = (
      <div className="row mb-s">
        <div className="col">
          <FormsySelect
            clearable={false}
            description={I18n.t('pages.info.form.favoriteLocation.help')}
            label={
              <Label title={I18n.t('pages.info.form.favoriteLocation.label')} />
            }
            name="favoriteLocation"
            options={options}
            placeholder={I18n.t('pages.info.form.favoriteLocation.placeholder')}
          />
        </div>
      </div>
    )
  }

  return (
    <Layout open animate>
      <Switch
        condition={!!fromCreditCard}
        fallback={
          <div
            className={`${styles.headerFinish} ${
              transition ? styles.formTransition : ''
            }`}
          >
            <Translate
              value={`pages.congrats.finishAccount`}
              className="grey-70-text body-text-2"
            />
          </div>
        }
      >
        <>
          <div
            className={`${styles.header} ${
              transition ? styles.headerTransition : ''
            }`}
          >
            <Header>
              <Translate value="pages.congrats.title" />
              <Translate
                value={`pages.congrats.subtitle.${
                  percent ? 'percent' : 'normal'
                }`}
                percent={percent}
                tag="p"
                className="grey-70-text body-text-2"
              />
            </Header>
          </div>

          <RewardContainer animate small={transition}>
            <RewardBlock description={description} percent={percent} />
          </RewardContainer>
        </>
      </Switch>
      <Spacer size="m" />
      {errorBanner}
      <Formsy
        className={`text-left mb-s ${styles.form} ${
          transition ? styles.formTransition : ''
        }`}
        mapping={inputs => ({
          ...inputs,
          phone: getDigits(inputs.phone || ''),
        })}
        onValidSubmit={submit}
      >
        <div className="row mb-s">
          <div className="col pr-xs">
            <FormsyInput
              name="firstName"
              label={<Label title={I18n.t('pages.info.form.firstName')} />}
              validations={{
                isRequired: true,
              }}
              validationErrors={{
                isRequired: I18n.t('validations.required'),
              }}
            />
          </div>
          <div className="col pl-xs">
            <FormsyInput
              label={<Label title={I18n.t('pages.info.form.lastName')} />}
              name="lastName"
              validations={{
                isRequired: true,
              }}
              validationErrors={{
                isRequired: I18n.t('validations.required'),
              }}
            />
          </div>
        </div>
        {favoriteLocationSelect}
        {phoneInput}
        <Button
          className="w-100"
          isLoading={isLoading}
          isSuccess={isSuccess}
          type="submit"
        >
          <Translate value="pages.info.form.button" />
        </Button>
      </Formsy>
    </Layout>
  )
}

export default Info
