// @flow
export function generatePrivacyPolicyUrl(appHandle?: string) {
  if (!appHandle) {
    return 'https://app.thanx.com/privacy'
  }

  return `https://app.thanx.com/privacy?app_handle=${appHandle}`
}

export function generateTermsOfServiceUrl(appHandle?: string) {
  if (!appHandle) {
    return 'https://app.thanx.com/terms'
  }

  return `https://app.thanx.com/terms?app_handle=${appHandle}`
}
