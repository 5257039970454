// @flow
import React from 'react'
import idx from 'idx'
import Header from 'components/Signup/Header'
import { Translate } from 'react-i18nify'
import Layout from 'components/Signup/Layout'
import { Spacer } from '@thanx/uikit/spacer'
import BackButton from 'components/Signup/BackButton'
import ContactSupport from 'components/Signup/ContactSupport'
import { useSignupData } from 'components/Signup/SignupData'
import styles from 'components/Signup/ContactSupport.module.scss'
import { getDownloadType } from 'utilities/useragent'
import Web from './Web'
import Mobile from './Mobile'

const App = () => {
  const signupData = useSignupData().signupData
  const userId = idx(signupData, _ => _.user.id)
  const appId = signupData.merchant.isBranded
    ? signupData.merchant.app.id
    : null

  const downloadType = getDownloadType()
  const downloadComponent =
    downloadType === 'web' ? <Web /> : <Mobile type={downloadType} />

  const email = idx(signupData, _ => _.user.email) || ''
  return (
    <Layout open>
      <BackButton to="../choose" />
      <Spacer size="s" />
      <Header className="mb-0">
        <Translate
          value="pages.app.header"
          tag="p"
          dangerousHTML
          className="px-xl mb-s body-text-3 grey-70-text normal"
        />

        <p className="body-text-2">{email}</p>
      </Header>

      <Spacer size="l" />

      {downloadComponent}

      <ContactSupport
        appId={appId}
        userId={userId}
        className={styles.contact}
      />
    </Layout>
  )
}

export default App
