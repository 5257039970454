// @flow
import React from 'react'

import type { Node } from 'react'

type PropsT = {
  children: Node,
  className: string,
}

const defaultProps = {
  className: '',
}

/*
 * Fine print wrapper.
 */
const FinePrint = ({ children, className }: PropsT) => (
  <div className={`body-text-6 grey-50-text ${className}`}>{children}</div>
)

FinePrint.defaultProps = defaultProps

export default FinePrint
