// @flow
import React, { useEffect } from 'react'
import { ProgressIcon } from './ProgressIcon.svg.js'
import { Translate } from 'react-i18nify'
import Header from 'components/Signup/Header'
import Layout from 'components/Signup/Layout'
import useTheme from 'hooks/useTheme'
import IconCircle from 'components/IconCircle'
import { Spacer } from '@thanx/uikit/spacer'
import { heapTrack } from 'utilities/analytics'
import { useSignupData } from 'components/Signup/SignupData'
import idx from 'idx'

import type { NavigateFn } from '@reach/router'
type Props = {
  navigate?: NavigateFn,
}

const Progress = ({ navigate }: Props) => {
  const { colors } = useTheme()
  const { signupData } = useSignupData()
  const { merchant, program } = signupData
  const isCardRequired = idx(program, _ => _.isCardRequired)

  const cardFormPath = isCardRequired
    ? '../track-progress-bonus'
    : '../track-progress'

  useEffect(() => {
    const timer = setTimeout(() => {
      if (navigate) navigate(cardFormPath)
    }, 3000)
    return () => clearTimeout(timer)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    heapTrack('Page Presented', {
      Page: 'Card Graphic',
      Experiment: 'Passwordless Signup Flow Experiment 2',
      Variant: 'Control & Prep user for card ask',
      Section: 'Signup',
      MerchantHandle: merchant.handle,
    })
  }, [merchant])

  return (
    <Layout>
      <Header>
        <Translate value="pages.progress.title" />
      </Header>
      <Spacer size="xl" />
      <IconCircle
        animate
        className="mx-auto mb-l"
        color={colors.primary50}
        size="140px"
      >
        <ProgressIcon color={colors.primary} />
      </IconCircle>
    </Layout>
  )
}

export default Progress
