// @flow
import { gql } from 'apollo-boost'
import { USER_FRAGMENT } from 'components/Signup/queries'

export const SIGN_UP_CURRENT_USER = gql`
  mutation signUpCurrentUser(
    $programId: ID!
    $referrerId: ID
    $signupCode: String
  ) {
    signUpCurrentUser(
      programId: $programId
      referrerId: $referrerId
      signupCode: $signupCode
    ) {
      user {
        ...UserFields
      }
      errors {
        status
      }
    }
  }
  ${USER_FRAGMENT}
`

export const Error = {
  USER_NOT_FOUND: 'user_not_found',
}
