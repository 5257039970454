// @flow
import { useEffect } from 'react'
import { useApolloClient } from 'react-apollo-hooks'
import isNil from 'lodash/isNil'
import { useSignupData } from 'components/Signup/SignupData'
import { MERCHANT_QUERY } from 'components/Signup/queries'

import type { NavigateFn } from '@reach/router'
import type { Node } from 'react'
import type { MerchantQuery } from 'components/Signup/__generated__/MerchantQuery'

type PropsT = {
  children: Node,
  merchantHandle?: string,
  navigate?: NavigateFn,
}

const Merchant = ({ children, merchantHandle, navigate }: PropsT) => {
  const client = useApolloClient()
  const { signupData, setSignupData } = useSignupData()

  useEffect(() => {
    async function fetchMerchant() {
      try {
        const { data }: { data: MerchantQuery } = await client.query({
          query: MERCHANT_QUERY,
          variables: {
            merchantHandle: merchantHandle,
          },
        })

        const merchant = data.merchant
        if (!merchant && navigate) navigate('/')
        setSignupData(state => ({
          ...state,
          merchant,
        }))
      } catch {
        if (navigate) navigate('/')
      }
    }

    if (isNil(merchantHandle)) {
      return
    }

    if (!signupData.merchant) {
      fetchMerchant()
    }

    // navigate doesn't maintain reference equality between renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantHandle, setSignupData])

  if (!signupData.merchant) return null
  return children
}

export default Merchant
