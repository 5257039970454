// @flow

import idx from 'idx'

type MerchantT = {
  +logoImage: any,
  +isBranded: boolean,
  +app: {
    +secondaryImage: any,
  },
}

export const getLogoImage = (merchant: MerchantT) => {
  if (!merchant.isBranded) return merchant.logoImage
  return idx(merchant, _ => _.app.secondaryImage) || merchant.logoImage
}
