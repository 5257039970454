export default {
  title: 'Enable SMS notifications',
  subtitle: 'We’ll send you notifications when you earn rewards',
  submitButton: 'Submit',
  downloadButton: 'Download the app instead',
  smsLegal_1:
    'By enabling SMS notifications you consent to receive recurring marketing text messages about your loyalty account sent by %{merchantName} and Thanx to the mobile number associated with your account.',
  smsLegal_2:
    'You understand and agree that text messages sent to your mobile number may be generated using automated technology, and you agree to our terms of service and privacy policy. Message and data rates may apply. Reply STOP to opt-out or HELP for info. Consent is not a condition of any purchase.',
}
