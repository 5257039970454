export default {
  title: {
    intro:
      'Join our rewards program today to stay in the know and earn rewards!',
    invite: 'Your friend %{name} just shared a reward with you!',
    invite_need: 'after your first purchase.',
    unknown:
      'That opportunity is no longer available, but you can still join our rewards program to stay in the know and earn rewards!',
  },
  legal:
    'By signing up you agree to our <a class="grey-50-text bold text-decoration-none grey-50-hover" href="%{privacyPolicyUrl}" target="_blank">Privacy Policy</a> and our <a class="grey-50-text bold text-decoration-none grey-50-hover" href="%{termsOfServiceUrl}" target="_blank">Terms of Service</a>.',
  button: 'Next',
  poweredByThanxAlt: 'powered-by-thanx',
  inputs: {
    email: 'email',
    password: {
      label: 'password',
      validation: 'Minimum number of characters is %{count}',
    },
  },
  errors: {
    invalid: 'This email address appears to be invalid',
    generic:
      'We had trouble creating this account. Check your email address and try again.',
    existingUsersDisallowed:
      'We’re sorry, this offer is only available to new users.',
  },
}
