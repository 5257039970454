// @flow
import fetch from 'isomorphic-fetch'
import queryString from 'query-string'

export class AuthClient {
  url: string
  clientId: string
  clientSecret: string
  scope: string
  redirectUri: string

  constructor() {
    this.url =
      process.env.GATSBY_AUTH_URL ||
      'http://localhost:1000/you-should-set-AUTH_URL-in-env/'
    this.clientId =
      process.env.GATSBY_CLIENT_ID || 'YOU SHOULD SET THE THANX CLIENT ID'
    this.clientSecret =
      process.env.GATSBY_CLIENT_SECRET ||
      'YOU SHOULD SET THE THANX CLIENT SECRET'
    this.redirectUri = window.location.origin + '/magic'
  }

  authorize(
    email: string,
    merchantHandle: string,
    programIdentifier: string
  ): Promise<*> {
    return fetch(`${this.url}/oauth/authorize`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Thanx-Merchant-Handle': merchantHandle,
      },
      body: JSON.stringify({
        client_id: this.clientId,
        redirect_uri: this.redirectUriWithParams(
          merchantHandle,
          programIdentifier
        ),
        response_type: 'code',
        username: email,
        scope: 'passwordless',
      }),
    })
  }

  token(
    code: string,
    merchantHandle: string,
    programIdentifier: string
  ): Promise<*> {
    return fetch(`${this.url}/oauth/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        client_id: this.clientId,
        client_secret: this.clientSecret,
        redirect_uri: this.redirectUriWithParams(
          merchantHandle,
          programIdentifier
        ),
        grant_type: 'authorization_code',
        code: code,
      }),
    })
  }

  redirectUriWithParams(merchantHandle: string, programIdentifier: string) {
    const parsedUri = queryString.parseUrl(this.redirectUri)
    var params = parsedUri.query
    params['merchantHandle'] = merchantHandle
    params['programIdentifier'] = programIdentifier
    return parsedUri.url + '?' + queryString.stringify(params)
  }
}
