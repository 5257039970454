// @flow
import React from 'react'
import { Button } from '@thanx/uikit/button'
import { Spacer } from '@thanx/uikit/spacer'
import Header from 'components/Signup/Header'
import { Translate } from 'react-i18nify'
import { Link } from '@reach/router'
import Layout from 'components/Signup/Layout'

const TrackProgress = () => (
  <Layout>
    <Header className="mb-0">
      <Translate
        value="pages.trackProgress.title"
        className="d-inline-block px-xl"
      />
    </Header>
    <Spacer size="xl" />
    <Link to={'../credit-card'} className="text-decoration-none">
      <Button className="d-flex flex-column w-100 py-m mb-s">
        <Translate
          value="pages.trackProgress.primaryBtn.main"
          className="body-text-3 d-block"
        />
      </Button>
    </Link>

    <Translate
      value="pages.trackProgress.or"
      className="grey-50-text body-text-4"
    />
    <Link to="../receipts" className="text-decoration-none">
      <Button className="d-flex flex-column w-100" kind="minimal_secondary">
        <Translate
          value="pages.trackProgress.secondaryBtn"
          className="body-text-3 d-block"
        />
      </Button>
    </Link>
  </Layout>
)

export default TrackProgress
