// @flow
import React from 'react'
import { EmailMagicLinkImage } from 'assets/images/EmailMagicLinkImage.js'
import { Translate } from 'react-i18nify'
import contactStyles from 'components/Signup/ContactSupport.module.scss'
import Header from 'components/Signup/Header'
import { useSignupData } from 'components/Signup/SignupData'
import Layout from 'components/Signup/Layout'
import BackButton from 'components/Signup/BackButton'
import ContactSupport from 'components/Signup/ContactSupport'
import { I18n } from 'react-i18nify'
import { Link } from '@reach/router'
import idx from 'idx'
import IconCircle from 'components/IconCircle'
import useTheme from 'hooks/useTheme'

const PasswordlessEmail = () => {
  const { colors } = useTheme()
  const { signupData } = useSignupData()
  const userId = idx(signupData, _ => _.user.id)
  const appId = idx(signupData, _ => _.merchant.app.id)

  return (
    <Layout>
      <BackButton to="../" />
      <Header>
        <Translate value="pages.passwordlessEmail.title" />
      </Header>

      <Translate
        value="pages.passwordlessEmail.subtitle"
        tag="p"
        className="grey-70-text body-text-2 mb-xl"
      />

      <IconCircle
        animate
        className="mx-auto mb-l"
        color={colors.primary50}
        size="140px"
      >
        <EmailMagicLinkImage color={colors.primary} />
      </IconCircle>
      <Link
        to="../"
        style={{ color: colors.primary }}
        className="mb-l text-decoration-none"
      >
        {I18n.t('pages.passwordlessEmail.resendButton')}
      </Link>
      <ContactSupport
        appId={appId}
        userId={userId}
        className={contactStyles.contact}
      />
    </Layout>
  )
}

export default PasswordlessEmail
