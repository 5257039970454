// @flow
import React from 'react'
import styles from './RewardBox.module.scss'

type PropsT = {
  animate: boolean,
  children: React$Node,
  className: string,
}

const defaultProps = {
  animate: false,
  className: '',
}

/**
 * A filled circle with an icon in the center.
 * Can be optionally animated by settings animate true.
 */

const RewardBox = (props: PropsT) => (
  <div
    className={`
      d-flex flex-column justify-content-center align-items-center px-s
      ${props.animate ? styles.animated : ''}
      ${props.className}
    `}
  >
    <div
      className={`border rounded shadow-sm w-100 px-s pb-s`}
      style={{
        minHeight: 90,
      }}
    >
      <div style={{ marginTop: -32 }}>{props.children}</div>
    </div>
  </div>
)

RewardBox.defaultProps = defaultProps

export default RewardBox
