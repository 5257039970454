import React from 'react'

export const Receipt = ({ color, className }) => (
  <svg
    width="54"
    height="54"
    viewBox="0 0 54 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0)">
      <path
        d="M41 36C36.0374 36 32.0001 40.0373 32.0001 45C32.0001 49.9626 36.0373 54 41 54C45.9628 54 50 49.9627 50 45.0001C50 40.0374 45.9626 36 41 36ZM41 52C37.1402 52 34.0001 48.8599 34.0001 45.0001C34.0001 41.1402 37.1402 38.0001 41 38.0001C44.8599 38.0001 48 41.1402 48 45.0001C48 48.8599 44.8599 52 41 52Z"
        fill={color}
      />
      <path
        d="M45.6401 41.2317C45.2158 40.8784 44.5854 40.9356 44.2317 41.3599L39.9329 46.5186L37.7071 44.293C37.3169 43.9023 36.6832 43.9023 36.2931 44.293C35.9024 44.6833 35.9024 45.3166 36.2931 45.707L39.293 48.7069C39.481 48.8949 39.7353 48.9999 40.0001 48.9999C40.0152 48.9999 40.0303 48.9997 40.0454 48.999C40.326 48.9863 40.5884 48.8559 40.7683 48.6401L45.7683 42.6401C46.1218 42.2159 46.0644 41.5855 45.6401 41.2317Z"
        fill={color}
      />
      <path
        d="M41 0H4.99993C4.4477 0 3.99988 0.447715 3.99988 1.00005V51C3.99988 51.3604 4.19373 51.6927 4.50718 51.8702C4.82021 52.0479 5.20518 52.043 5.5143 51.8575L9.93787 49.2035L13.3998 51.8001C13.7253 52.0443 14.1661 52.0668 14.5143 51.8575L18.9378 49.2035L22.3997 51.8001C22.577 51.9329 22.7879 52.0001 22.9999 52.0001C23.1774 52.0001 23.3556 51.953 23.5142 51.8575L28.5142 48.8576C28.9879 48.5734 29.1414 47.9591 28.8572 47.4857C28.573 47.0124 27.9585 46.8585 27.4853 47.1427L23.0618 49.7968L19.5999 47.2001C19.274 46.956 18.8335 46.9333 18.4854 47.1427L14.0618 49.7968L10.5999 47.2001C10.274 46.956 9.83357 46.9333 9.48541 47.1427L5.99999 49.2339V2H40V33C40 33.5522 40.4477 34 40.9999 34C41.5521 34 42 33.5522 42 33V1.00005C42 0.447715 41.5522 0 41 0Z"
        fill={color}
      />
      <path
        d="M15 8.99994H9.99999C9.44776 8.99994 8.99994 9.44765 8.99994 9.99999C8.99994 10.5523 9.44765 11 9.99999 11H15C15.5522 11 15.9999 10.5523 15.9999 9.99999C15.9999 9.44765 15.5522 8.99994 15 8.99994Z"
        fill={color}
      />
      <path
        d="M34 14.0001H29.0001C28.4478 14.0001 28.0001 14.4478 28.0001 15.0001C28.0001 15.5525 28.4478 16.0001 29.0001 16.0001H34C34.5523 16.0001 35.0001 15.5524 35.0001 15.0001C35.0001 14.4479 34.5524 14.0001 34 14.0001Z"
        fill={color}
      />
      <path
        d="M34 19.0001H29.0001C28.4478 19.0001 28.0001 19.4478 28.0001 20.0001C28.0001 20.5525 28.4478 21.0001 29.0001 21.0001H34C34.5523 21.0001 35.0001 20.5523 35.0001 20.0001C35.0001 19.4479 34.5524 19.0001 34 19.0001Z"
        fill={color}
      />
      <path
        d="M34 24.0001H29.0001C28.4478 24.0001 28.0001 24.4478 28.0001 25.0002C28.0001 25.5525 28.4478 26.0001 29.0001 26.0001H34C34.5523 26.0001 35.0001 25.5524 35.0001 25.0002C35.0001 24.4479 34.5524 24.0001 34 24.0001Z"
        fill={color}
      />
      <path
        d="M34 29.0001H29.0001C28.4478 29.0001 28.0001 29.4478 28.0001 30.0002C28.0001 30.5525 28.4478 31.0001 29.0001 31.0001H34C34.5523 31.0001 35.0001 30.5524 35.0001 30.0002C35.0001 29.4479 34.5524 29.0001 34 29.0001Z"
        fill={color}
      />
      <path
        d="M34 33.9999H29.0001C28.4478 33.9999 28.0001 34.4476 28.0001 34.9999C28.0001 35.5523 28.4478 35.9999 29.0001 35.9999H34C34.5523 35.9999 35.0001 35.5522 35.0001 34.9999C35.0001 34.4477 34.5524 33.9999 34 33.9999Z"
        fill={color}
      />
      <path
        d="M24 14.0001H9.99999C9.44776 14.0001 8.99994 14.4478 8.99994 15.0001C8.99994 15.5525 9.44765 16.0001 9.99999 16.0001H24C24.5523 16.0001 25.0001 15.5524 25.0001 15.0001C25.0001 14.4479 24.5523 14.0001 24 14.0001Z"
        fill={color}
      />
      <path
        d="M24 19.0001H9.99999C9.44776 19.0001 8.99994 19.4478 8.99994 20.0001C8.99994 20.5525 9.44765 21.0001 9.99999 21.0001H24C24.5523 21.0001 25.0001 20.5523 25.0001 20.0001C25.0001 19.4479 24.5523 19.0001 24 19.0001Z"
        fill={color}
      />
      <path
        d="M24 24.0001H9.99999C9.44776 24.0001 8.99994 24.4478 8.99994 25.0002C8.99994 25.5525 9.44765 26.0001 9.99999 26.0001H24C24.5523 26.0001 25.0001 25.5524 25.0001 25.0002C25.0001 24.4479 24.5523 24.0001 24 24.0001Z"
        fill={color}
      />
      <path
        d="M24 29.0001H9.99999C9.44776 29.0001 8.99994 29.4478 8.99994 30.0002C8.99994 30.5525 9.44765 31.0001 9.99999 31.0001H24C24.5523 31.0001 25.0001 30.5524 25.0001 30.0002C25.0001 29.4479 24.5523 29.0001 24 29.0001Z"
        fill={color}
      />
      <path
        d="M24 33.9999H9.99999C9.44776 33.9999 8.99994 34.4476 8.99994 34.9999C8.99994 35.5523 9.44765 35.9999 9.99999 35.9999H24C24.5523 35.9999 25.0001 35.5522 25.0001 34.9999C25.0001 34.4477 24.5523 33.9999 24 33.9999Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="54" height="54" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
