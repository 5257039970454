export default {
  title: 'Get %{redeemText} after you register your card for rewards',
  altTitle: 'Confirm the card you want to earn rewards with',
  numberInput: {
    label: 'credit card',
    cardTypes: {
      amex: 'American Express',
      generic: 'Unsupported',
      mastercard: 'Mastercard',
      visa: 'Visa',
    },
  },
  button: 'Confirm card',
  finePrint: {
    transactionSharing:
      'By registering my card with %{merchant} rewards powered by Thanx, I authorize my card network (Visa, Mastercard, Amex) to share how much I spend when I make a purchase in order to earn rewards. I also accept the <a target="_blank" class="grey-90-text bold grey-90-hover underline" href="%{termsOfServiceUrl}">Terms of Service</a> and <a target="_blank" class="grey-90-text bold grey-90-hover underline" href="%{privacyPolicyUrl}">Privacy Policy</a>.',
  },
  errors: {
    alreadyEnrolled: 'This card is already enrolled.',
    generic: 'We could not register your card. Try again or contact support.',
  },
}
