// @flow
import React from 'react'
import { useSignupData } from 'components/Signup/SignupData'
import { Button } from '@thanx/uikit/button'
import { I18n } from 'react-i18nify'

type PropsT = {
  type: string,
}

const Mobile = ({ type }: PropsT) => {
  const url = useSignupData().signupData.merchant.app.urls[type]
  return (
    <a href={url} className="text-decoration-none">
      <Button className="w-100">{I18n.t('pages.app.getApp')}</Button>
    </a>
  )
}

export default Mobile
