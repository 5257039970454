// @flow
import React, { createContext, useState } from 'react'

import type { Node } from 'react'
import type { ProgramQuery_merchant_program as ProgramT } from 'components/Signup/__generated__/ProgramQuery'
import type { MerchantQuery_merchant as MerchantT } from 'components/Signup/__generated__/MerchantQuery'
import type { UserQuery_user as UserT } from 'components/Signup/__generated__/UserQuery'

export type SignupDataT = {
  merchant: MerchantT,
  program?: ?ProgramT,
  user?: ?UserT,
}

export const SignupDataContext = createContext<{
  signupData: SignupDataT,
  setSignupData(any): void,
}>({})

export const SignupDataProvider = ({
  children,
  initialValue,
}: {|
  children: Node,
  initialValue: Object,
|}) => {
  const [signupData, setSignupData] = useState(initialValue)

  return (
    <SignupDataContext.Provider value={{ signupData, setSignupData }}>
      {children}
    </SignupDataContext.Provider>
  )
}

SignupDataProvider.defaultProps = {
  initialValue: {},
}

export function useSignupData() {
  const context = React.useContext(SignupDataContext)

  if (context === undefined) {
    throw new Error('useSignupData must be used within a SignupDataProvider')
  }
  return context
}
