// @flow
import { createContext, useContext } from 'react'

type PathSegmentsContextT = {
  merchantHandle: string,
  programIdentifier: string,
}

export const PathSegmentsContext = createContext<PathSegmentsContextT>({})

export function usePathSegments() {
  const context = useContext(PathSegmentsContext)

  if (context === undefined) {
    throw new Error(
      'usePathSegments must be used within a PathSegmentsContext.Provider'
    )
  }
  return context
}
