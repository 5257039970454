export default {
  title:
    'How would you like to view rewards <br>& <br>get notified when you’ve earned new ones?',
  or: 'or',
  downloadButton: 'Download the app',
  smsButton: {
    enable: 'Enable SMS',
    continue: 'Continue with SMS',
  },
  errors: {
    sms: 'We are having trouble messaging your number.',
  },
}
