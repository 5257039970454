import React from 'react'

const RewardIcon = ({ color }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
      fill={color}
    />
    <path
      d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
      fill={color}
    />
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.45">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9527 35L20 32.8697L22.0473 35L23.4452 32.3951L25.99 33.8927L26.6349 31.0064L29.4884 31.7603L29.3326 28.8066L32.2832 28.7609L31.3381 25.9588L34.1669 25.1169L32.5026 22.6743L35 21.0986L32.74 19.1967L34.7206 17.0041L32.0324 15.7839L33.3494 13.1369L30.4325 12.6889L30.9882 9.78398L28.0588 10.1414L27.812 7.19393L25.0875 8.33028L24.0564 5.55884L21.7388 7.38983L20 5L18.2612 7.38983L15.9436 5.55884L14.9125 8.33028L12.188 7.19393L11.9412 10.1414L9.01184 9.78398L9.56752 12.6889L6.6506 13.1369L7.96759 15.7839L5.27942 17.0041L7.26004 19.1967L5 21.0986L7.49737 22.6743L5.83306 25.1169L8.66195 25.9588L7.71682 28.7609L10.6674 28.8066L10.5116 31.7603L13.3651 31.0064L14.01 33.8927L16.5548 32.3951L17.9527 35ZM18.0748 25.8235H18.0756L27.1061 17.0293L24.8419 14.8235L18.0748 21.4136L15.3711 18.7798L13.1061 20.984L18.0748 25.8235Z"
        fill="#36424E"
      />
    </g>
    <g opacity="0.6">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0756 25.8235H18.0748L13.1061 20.984L15.3711 18.7798L18.0748 21.4136L24.8419 14.8235L27.1061 17.0293L18.0756 25.8235Z"
        fill="white"
      />
    </g>
  </svg>
)

export default RewardIcon
