// @flow
import React from 'react'
import { LDProvider } from 'launchdarkly-react-client-sdk'

type PropsT = {
  merchantHandle: ?string,
  children: React$Node,
}

const LaunchDarklyProvider = ({ merchantHandle, children }: PropsT) => {
  return (
    <LDProvider
      clientSideID={process.env.GATSBY_LAUNCH_DARKLY_KEY || ''}
      reactOptions={{ useCamelCaseFlagKeys: false }}
      options={{ bootstrap: 'localStorage' }}
      user={{
        anonymous: true,
        custom: {
          merchantHandle: merchantHandle,
        },
      }}
    >
      {children}
    </LDProvider>
  )
}

export default LaunchDarklyProvider
