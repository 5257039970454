// @flow
import { useEffect } from 'react'
import { navigate } from 'gatsby'
import queryString from 'query-string'
import idx from 'idx'

type PropsT = {
  location?: {
    search?: string,
  },
}

const PasswordlessLanding = (props: PropsT) => {
  const urlParams = idx(props, _ => _.location.search)

  useEffect(() => {
    const { merchantHandle, programIdentifier, code } = queryString.parse(
      urlParams
    )
    if (merchantHandle && programIdentifier && code) {
      navigate(`${merchantHandle}/${programIdentifier}/magic?code=${code}`, {
        replace: true,
      })
    } else {
      navigate('/')
    }
  }, [urlParams])

  return null
}

export default PasswordlessLanding
