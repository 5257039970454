// @flow
import React from 'react'
import styles from './reward.module.scss'
import { Translate } from 'react-i18nify'
import { ProgressBar } from 'baseui/progress-bar'
import useTheme from 'hooks/useTheme'
import RewardIcon from './RewardIcon.js'

type PropsT = {
  description: string,
  percent: ?number,
}

const RewardBlock = (props: PropsT) => {
  const { colors } = useTheme()

  let additional
  if (props.percent) {
    additional = (
      <div className="text-right mt-n1">
        <div className="grey-50-text body-text-6">{props.percent}%</div>
        <ProgressBar
          value={props.percent}
          successValue={100}
          overrides={{
            Bar: {
              style: {
                margin: '4px 2px',
              },
            },
          }}
        />
      </div>
    )
  } else {
    additional = (
      <Translate
        value="pages.congrats.rewardText"
        className="body-text-4 grey-50-text d-block"
      />
    )
  }
  return (
    <div
      className={`d-flex grey-30-border border rounded p-s ${
        styles.rewardBlock
      }`}
    >
      <RewardIcon color={colors.primary} />

      <div className="ml-s flex-grow-1 text-left mt-n1">
        <span className="body-text-3 grey-90-text d-block">
          {props.description}
        </span>
        {additional}
      </div>
    </div>
  )
}

export default RewardBlock
