import React from 'react'

export const Spinner = ({ color, className }) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 29.6668C6.91331 29.6668 0.333313 23.0868 0.333313 15.0002C0.333313 9.7815 3.14131 4.91483 7.66398 2.2975L8.99998 4.60416C5.29998 6.74683 2.99998 10.7295 2.99998 15.0002C2.99998 21.6175 8.38265 27.0002 15 27.0002C21.6173 27.0002 27 21.6175 27 15.0002C27 8.38283 21.6173 3.00016 15 3.00016V0.333496C23.0866 0.333496 29.6666 6.9135 29.6666 15.0002C29.6666 23.0868 23.0866 29.6668 15 29.6668Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="30" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
