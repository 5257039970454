// @flow
import React from 'react'
import cardAmex from 'assets/images/card_amex.svg'
import cardGeneric from 'assets/images/card_generic.svg'
import cardMastercard from 'assets/images/card_mastercard.svg'
import cardVisa from 'assets/images/card_visa.svg'
import { I18n } from 'react-i18nify'

import type { Node } from 'react'

export const CardType = {
  AMEX: 'amex',
  MASTERCARD: 'mastercard',
  VISA: 'visa',
}

// Translate the card type from ``
export function getCardTypeFromCardValidatorType(type: string): string | null {
  if (type === null) {
    return null
  }
  if (type === 'american-express') {
    return CardType.AMEX
  }
  if (type === 'mastercard') {
    return CardType.MASTERCARD
  }
  if (type === 'visa') {
    return CardType.VISA
  }

  return type
}

export function getCardIcon(cardType: string | null): Node {
  let cardIcon
  if (cardType === null) {
    cardIcon = null
  } else {
    let src
    let alt
    if (cardType === CardType.VISA) {
      src = cardVisa
      alt = I18n.t('pages.creditCard.numberInput.cardTypes.visa')
    } else if (cardType === CardType.MASTERCARD) {
      src = cardMastercard
      alt = I18n.t('pages.creditCard.numberInput.cardTypes.mastercard')
    } else if (cardType === CardType.AMEX) {
      src = cardAmex
      alt = I18n.t('pages.creditCard.numberInput.cardTypes.amex')
    } else {
      src = cardGeneric
      alt = I18n.t('pages.creditCard.numberInput.cardTypes.generic')
    }

    cardIcon = <img className="pr-s" src={src} alt={alt} />
  }

  return cardIcon
}
