// @flow
import React from 'react'
import styles from './reward.module.scss'
import useTheme from 'hooks/useTheme'

type Props = {
  small: boolean,
  animate: boolean,
  children: React$Node,
}

const RewardContainer = (props: Props) => {
  const { colors } = useTheme()

  return (
    <div
      className={`d-flex justify-content-center flex-column ${
        styles.rewardContainer
      }`}
    >
      <div
        className={`${styles.circle} ${props.small ? styles.small : ''}`}
        style={{ backgroundColor: colors.primary50 }}
      />
      <div
        className={`position-absolute ${styles.rewardWrapper} ${
          props.animate ? styles.animated : ''
        }`}
      >
        {props.children}
      </div>
    </div>
  )
}

RewardContainer.defaultProps = {
  small: false,
  animate: false,
}

export default RewardContainer
