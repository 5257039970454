// @flow
import React, { useState } from 'react'
import { Router, Location } from '@reach/router'
import styles from './TransitionRouter.module.scss'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import type { Node } from 'react'
type PropsT = {
  children: Node,
}

const TransitionRouter = (props: PropsT) => {
  const [enteringHeight, setEnteringHeight] = useState(0)

  const onEnter = entering => {
    // When the new card first appears, we store it's height for later use.
    // Then we look for an existing card and, if it exists, set the height
    // of the new and existing card to that card's height.
    const enteringTarget = entering.getElementsByClassName('card')[0]
    const enteringHeight = enteringTarget.getBoundingClientRect().height
    setEnteringHeight(enteringHeight)

    const exitingTarget = Array.from(
      document.getElementsByClassName('card')
    ).filter(element => element !== enteringTarget)[0]
    if (exitingTarget) {
      const exitingHeight = exitingTarget.getBoundingClientRect().height
      exitingTarget.style.maxHeight = `${exitingHeight + 24}px`
      enteringTarget.style.maxHeight = `${exitingHeight + 24}px`
      enteringTarget.style.height = `${exitingHeight}px`
    }
  }

  const onEntering = entering => {
    // restore the card's original height. a css transition
    // animates it from the previous card's height to the
    // original height.
    const target = entering.getElementsByClassName('card')[0]
    target.style.maxHeight = `${enteringHeight + 24}px`
    target.style.height = `${enteringHeight}px`
  }

  function onEntered(entering) {
    const target = entering.getElementsByClassName('card')[0]
    target.style.maxHeight = null
    target.style.height = null
  }

  return (
    <Location>
      {({ location }) => (
        <TransitionGroup>
          <CSSTransition
            // $FlowFixMe
            key={location.key}
            timeout={1100}
            classNames={{
              enter: styles.cardTransitionEnter,
              enterActive: styles.cardTransitionEnterActive,
              exit: styles.cardTransitionLeave,
              exitActive: styles.cardTransitionLeaveActive,
            }}
            onEnter={onEnter}
            onEntering={onEntering}
            onEntered={onEntered}
          >
            <Router location={location} key="router">
              {props.children}
            </Router>
          </CSSTransition>
        </TransitionGroup>
      )}
    </Location>
  )
}

export default TransitionRouter
