// @flow
import React from 'react'
import { getLogoImage } from 'utilities/images'
import { useSignupData } from 'components/Signup/SignupData'
import styles from './Layout.module.scss'

import type { Node } from 'react'

type PropsT = {
  children: Node,
  open: boolean,
  animate: boolean,
}

const Layout = ({ children, open, animate }: PropsT) => {
  const {
    signupData: { merchant },
  } = useSignupData()
  const { name } = merchant

  return (
    <div
      className={`${styles.layout} ${open ? styles.open : ''} ${
        animate ? styles.animate : ''
      }`}
    >
      <img
        src={getLogoImage(merchant)}
        alt={name}
        className={`${styles.logoImage} mb-m`}
      />
      <div className={`${styles.cardContainer} card-container`}>
        <div className={`white-bkg card py-l px-m w-100 ${styles.card}`}>
          <div className={`${styles.centralColumn}`}>{children}</div>
        </div>
      </div>
    </div>
  )
}

Layout.defaultProps = {
  open: false,
  animate: false,
}

export default Layout
