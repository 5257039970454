// @flow
import React, { useState, useEffect } from 'react'
import isEmpty from 'lodash/isEmpty'
import queryString from 'query-string'
import { AuthClient } from 'clients/auth'
import { useApolloClient, useMutation } from 'react-apollo-hooks'
import { I18n } from 'react-i18nify'
import { setToken, getToken } from 'utilities/token'
import idx from 'idx'
import { Spinner } from 'assets/images/spinner.svg.js'
import { Translate } from 'react-i18nify'
import styles from './PasswordlessLogin.module.scss'
import contactStyles from 'components/Signup/ContactSupport.module.scss'
import Header from 'components/Signup/Header'
import { useSignupData } from 'components/Signup/SignupData'
import Layout from 'components/Signup/Layout'
import ContactSupport from 'components/Signup/ContactSupport'
import useTheme from 'hooks/useTheme'
import Error from './Error'
import { SIGN_UP_CURRENT_USER } from './mutations'

import type { NavigateFn } from '@reach/router'
import type {
  signUpCurrentUser as SignUpCurrentUserT,
  signUpCurrentUser_signUpCurrentUser_user as UserT,
} from './__generated__/signUpCurrentUser'

type Props = {
  merchantHandle?: string,
  programIdentifier?: string,
  location?: {
    search?: string,
  },
  navigate?: NavigateFn,
}

const translate = (error: string) => I18n.t(`pages.passwordlessLogin.${error}`)

const PasswordlessLogin = ({
  merchantHandle,
  programIdentifier,
  location,
  navigate,
}: Props) => {
  const [error, setError] = useState(null)
  const [hasRedirected, setHasRedirected] = useState(false)

  const { colors } = useTheme()
  const apolloClient = useApolloClient()
  const signUpCurrentUser = useMutation(SIGN_UP_CURRENT_USER)

  const { signupData, setSignupData } = useSignupData()
  const appId = idx(signupData, _ => _.merchant.app.id)
  const userId = idx(signupData, _ => _.user.id)
  const program = signupData.program

  const urlParams = idx(location, _ => _.search)

  useEffect(() => {
    const { code } = queryString.parse(urlParams)
    const requestAccessToken = async (code: string) => {
      try {
        if (!merchantHandle || !programIdentifier || !code)
          throw 'Missing merchant handle, program identifier or code'

        const authClient = new AuthClient()
        const response = await authClient.token(
          code,
          merchantHandle,
          programIdentifier
        )
        if (!response.ok) throw response

        const data = await response.json()
        setToken(idx(data, _ => _.access_token))
      } catch (response) {
        if (response && response.status === 401) {
          setError(translate('error.invalidCode'))
          return
        }
        setError(translate('error.generic'))
      }
    }

    const signUpUser = async () => {
      if (!program || hasRedirected) return

      let result
      try {
        result = await signUpCurrentUser({
          variables: {
            programId: program.id,
            // $FlowIgnore: This is safe
            referrerId: idx(program, _ => _.referrer.id),
          },
          errorPolicy: 'all',
        })
      } catch (error) {
        setError(I18n.t('errors.network'))
        return
      }

      const {
        data,
        error: graphqlError,
      }: { data: SignUpCurrentUserT, error: Object } = result

      // GraphQL Errors
      if (graphqlError) {
        setError(translate('error.generic'))
        return
      }

      // App errors
      if (!isEmpty(idx(data, _ => _.signUpCurrentUser.errors))) {
        setError(translate('error.generic'))
        return
      }

      // $FlowIgnore: This will always exist if there's no errors
      const user: UserT = data.signUpCurrentUser.user
      setSignupData(state => ({
        ...state,
        user: user,
      }))

      let redirectTo = '../track-progress'

      if (user.state === 'registered') {
        redirectTo = '../all-set'
      }

      if (user.state === 'account_created' && !isEmpty(user.cards)) {
        redirectTo = '../info'
      }

      if (navigate) {
        navigate(redirectTo)
        setHasRedirected(true)
      }
    }

    const loginSignUpAndRedirect = async () => {
      await requestAccessToken(code)
      if (getToken()) await signUpUser()
    }

    if (!program) return

    loginSignUpAndRedirect()
    // navigate doesn't maintain reference equality
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    apolloClient,
    urlParams,
    merchantHandle,
    programIdentifier,
    program,
    hasRedirected,
    setHasRedirected,
  ])

  if (error) return <Error error={error} />

  return (
    <Layout>
      <Header>
        <Translate value="pages.passwordlessLogin.title" />
      </Header>

      <Translate
        value="pages.passwordlessLogin.subtitle"
        tag="p"
        className="grey-70-text body-text-2 mb-xl"
      />

      <div className="px-xs mb-xxl" />
      <div className="align-bottom mb-xl">
        <Spinner className={styles.spinner} color={colors.primary} />
      </div>

      <ContactSupport
        appId={appId}
        userId={userId}
        className={contactStyles.contact}
      />
    </Layout>
  )
}

export default PasswordlessLogin
