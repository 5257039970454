// @flow
import React from 'react'
import type { Node } from 'react'

type PropsT = {
  children: Node,
  fallback?: Node,
  condition: boolean,
}

const Switch = (props: PropsT) => {
  const { condition, children, fallback = null } = props
  if (condition) {
    return <>{children}</>
  }
  return <>{fallback}</>
}

export default Switch
