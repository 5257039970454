// @flow
import React from 'react'
import { EmailMagicLinkImage } from 'assets/images/EmailMagicLinkImage.js'
import IconCircle from 'components/IconCircle'
import { I18n } from 'react-i18nify'
import { Spacer } from '@thanx/uikit/spacer'
import { Link } from '@reach/router'
import contactStyles from 'components/Signup/ContactSupport.module.scss'
import Header from 'components/Signup/Header'
import { useSignupData } from 'components/Signup/SignupData'
import Layout from 'components/Signup/Layout'
import ContactSupport from 'components/Signup/ContactSupport'
import BackButton from 'components/Signup/BackButton'
import useTheme from 'hooks/useTheme'
import idx from 'idx'

type PropsT = {
  error: string,
}

const Error = ({ error }: PropsT) => {
  const { signupData } = useSignupData()
  const userId = idx(signupData, _ => _.user.id)
  const appId = idx(signupData, _ => _.merchant.app.id)
  const { colors } = useTheme()

  return (
    <Layout>
      <BackButton to="../" />

      <Header>{error}</Header>
      <Spacer size="xl" />
      <IconCircle
        animate
        className="mx-auto mb-l"
        color={colors.primary50}
        size="140px"
      >
        <EmailMagicLinkImage color={colors.primary} />
      </IconCircle>

      <Link
        to="../"
        style={{ color: colors.primary }}
        className="mb-l text-decoration-none"
      >
        {I18n.t('pages.passwordlessEmail.resendButton')}
      </Link>

      <ContactSupport
        appId={appId}
        userId={userId}
        className={contactStyles.contact}
      />
    </Layout>
  )
}

export default Error
