// @flow
import React from 'react'
import SEO from 'components/Signup/SEO'
import { useSignupData } from 'components/Signup/SignupData'
import styles from './Layout.module.scss'

type PropsT = {
  children: React$Element<any>,
}

const LayoutWrapper = ({ children }: PropsT) => {
  const { signupData } = useSignupData()
  const { merchant } = signupData
  const { coverImage } = merchant

  return (
    <div
      className={`w-100 text-center grey-40-bkg ${styles.layoutWrapper}`}
      style={{
        backgroundImage: `url(${coverImage})`,
      }}
    >
      <SEO />
      {children}
    </div>
  )
}

export default LayoutWrapper
