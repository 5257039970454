import React from 'react'

export const Gift = ({ color, className }) => (
  <svg
    width="54"
    height="54"
    viewBox="0 0 54 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M50.4783 20.2499H3.52185C2.87385 20.2499 2.3479 20.754 2.3479 21.375V52.875C2.3479 53.4958 2.87385 53.9999 3.52185 53.9999H50.4784C51.1264 53.9999 51.6523 53.4958 51.6523 52.8748V21.375C51.6522 20.754 51.1263 20.2499 50.4783 20.2499ZM49.3044 51.7499H4.69569V22.4999H49.3043V51.7499H49.3044Z"
      fill={color}
    />
    <path
      d="M52.8262 11.25H1.17395C0.525949 11.25 0 11.754 0 12.375V21.375C0 21.996 0.525949 22.5 1.17395 22.5H52.8261C53.4741 22.5 54 21.996 54 21.375V12.375C54 11.7539 53.4742 11.25 52.8262 11.25ZM51.6522 20.2499H2.3479V13.5H51.6522V20.2499Z"
      fill={color}
    />
    <path
      d="M31.549 11.25C30.901 11.25 30.3751 11.754 30.3751 12.375V52.875C30.3751 53.496 30.901 54 31.549 54C32.197 54 32.723 53.496 32.723 52.875V12.375C32.723 11.7539 32.197 11.25 31.549 11.25Z"
      fill={color}
    />
    <path
      d="M23.6739 11.25C23.026 11.25 22.5 11.754 22.5 12.375V52.875C22.5 53.496 23.026 54 23.6739 54C24.3219 54 24.8479 53.496 24.8479 52.875V12.375C24.8479 11.7539 24.3219 11.25 23.6739 11.25Z"
      fill={color}
    />
    <path
      d="M28.1998 12.1095C26.9931 6.6847 19.7946 0.863998 14.998 0.085535C14.7022 0.0292148 14.3993 0 14.0871 0C11.4974 0 9.39142 2.01825 9.39142 4.50003C9.39142 5.53731 9.76946 6.54981 10.455 7.35306C10.4643 7.35981 10.4714 7.37109 10.4784 7.38006C13.9649 11.6483 25.5703 13.2841 26.8897 13.4573C26.9438 13.464 26.9977 13.4685 27.0494 13.4685C27.3757 13.4685 27.6856 13.3403 27.911 13.1086C28.1716 12.8407 28.2797 12.4672 28.1998 12.1095ZM12.2956 5.95127C12.2745 5.92206 12.2511 5.89727 12.2275 5.87249C11.9082 5.47877 11.7392 5.00396 11.7392 4.50003C11.7392 3.25803 12.7934 2.25007 14.087 2.25007C14.242 2.25007 14.3946 2.26578 14.5753 2.29732C18.0947 2.86885 23.4078 6.95027 25.3166 10.9328C21.2292 10.2105 14.3289 8.52523 12.2956 5.95127Z"
      fill={color}
    />
    <path
      d="M39.9131 0C39.6009 0 39.2981 0.0292148 39.035 0.078785C34.2079 0.863998 27.0095 6.6847 25.8028 12.1095C25.723 12.4672 25.8309 12.8407 26.0915 13.1085C26.3146 13.338 26.6268 13.4684 26.9508 13.4684C27.0025 13.4684 27.0565 13.4639 27.1081 13.4595C28.4275 13.2862 40.0329 11.6505 43.5194 7.38227C43.5288 7.37331 43.5358 7.36202 43.5453 7.35306C44.2308 6.5497 44.6088 5.5372 44.6088 4.50003C44.6088 2.01825 42.5028 0 39.9131 0ZM41.7726 5.8747C41.7492 5.89949 41.7256 5.92417 41.7045 5.95349C39.6737 8.52302 32.7734 10.2127 28.6834 10.935C30.5945 6.94795 35.91 2.86874 39.4576 2.29278C39.6055 2.26578 39.7582 2.25007 39.9131 2.25007C41.2068 2.25007 42.2609 3.25803 42.2609 4.50003C42.261 5.00396 42.092 5.47645 41.7726 5.8747Z"
      fill={color}
    />
  </svg>
)
