// @flow
import React from 'react'
import { I18n } from 'react-i18nify'
import ToasterIcon from './ToasterIcon'
import styles from './NotFound.module.scss'

const NotFound = () => (
  <div
    className={`
      ${styles.container} w-100
      d-flex align-content-center justify-content-center flex-column
      text-center px-m
    `}
  >
    <div className="mb-xxxl">
      <ToasterIcon />
    </div>
    <div className="grey-90-text body-text-1 bold mb-xs">
      {I18n.t('pages.notFound.title')}
    </div>
    <div className="body-text-2 grey-70-text mb-l">
      {I18n.t('pages.notFound.subtitle')}
    </div>
    <div className="body-text-3 grey-70-text">
      {I18n.t('pages.notFound.cta')}{' '}
      <a href="mailto:support@thanx.com" className={'grey-90-text underline'}>
        {I18n.t('pages.notFound.link')}
      </a>
    </div>
  </div>
)

export default NotFound
