// @flow
import ahoy from 'ahoy.js'
import { buildAuthorizationHeader, getToken } from 'utilities/token'

const SIGNUP_EVENT_NAME = 'signup'

export function resetVisit() {
  const token = getToken()
  let headers = {}
  if (token) {
    headers['Authorization'] = buildAuthorizationHeader(token)
  }
  ahoy.configure({
    headers: headers,
  })

  ahoy.reset()
  ahoy.start()
}

export function trackSignup(email: string) {
  ahoy.track(SIGNUP_EVENT_NAME, { email })
}

export function heapTrack(eventName: string, eventDetails: {}) {
  if (typeof window !== 'undefined' && window.heap) {
    window.heap.track(eventName, eventDetails)
  }
}
