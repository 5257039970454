// @flow
import React from 'react'
import LayoutWrapper from '../Layout/LayoutWrapper'
import CreditCard from './CreditCard'
import Welcome from './Welcome'
import Reward from './Reward'
import PasswordlessEmail from './PasswordlessEmail'
import PasswordlessLogin from './PasswordlessLogin'
import TrackProgress from './TrackProgress'
import TrackProgressBonus from './TrackProgressBonus'
import Receipts from './Receipts'
import Info from './Info'
import AllSet from './AllSet'
import Choose from './Choose'
import App from './App'
import Sms from './Sms'
import Confirmation from './Confirmation'
import PrivateRoute from '../PrivateRoute'
import TransitionRouter from '../TransitionRouter'
import { PathSegmentsContext } from '../PathSegments'
import Benefits from './Benefits'
import Progress from './Progress'

type PropsT = {
  merchantHandle: string,
  programIdentifier: string,
}

/**
 * Router for the screens in the signup flow.
 */
const Screens = ({ merchantHandle, programIdentifier }: PropsT) => {
  return (
    <PathSegmentsContext.Provider value={{ merchantHandle, programIdentifier }}>
      <LayoutWrapper>
        <TransitionRouter>
          <Welcome path="/" />
          <PasswordlessEmail path="passwordless-email" />
          <PasswordlessLogin path="magic" />
          <PrivateRoute path="reward" component={Reward} />
          <PrivateRoute path="benefits" component={Benefits} />
          <PrivateRoute path="progress" component={Progress} />
          <PrivateRoute path="track-progress" component={TrackProgress} />
          <PrivateRoute
            path="track-progress-bonus"
            component={TrackProgressBonus}
          />
          <PrivateRoute path="receipts" component={Receipts} />
          <PrivateRoute path="credit-card" component={CreditCard} />
          <PrivateRoute path="info" component={Info} />
          <PrivateRoute path="all-set" component={AllSet} />
          <PrivateRoute path="choose" component={Choose} />
          <PrivateRoute path="app" component={App} />
          <PrivateRoute path="sms" component={Sms} />
          <PrivateRoute path="confirmation" component={Confirmation} />
        </TransitionRouter>
      </LayoutWrapper>
    </PathSegmentsContext.Provider>
  )
}

export default Screens
