// @flow
import React, { useEffect } from 'react'
import Header from 'components/Signup/Header'
import { Translate } from 'react-i18nify'
import { Spacer } from '@thanx/uikit/spacer'
import { Link } from '@reach/router'
import { Button } from '@thanx/uikit/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import Layout from 'components/Signup/Layout'
import useTheme from 'hooks/useTheme'
import { useSignupData } from 'components/Signup/SignupData'
import { heapTrack } from 'utilities/analytics'

const Benefits = () => {
  const { colors } = useTheme()
  const { signupData } = useSignupData()
  const { merchant } = signupData

  useEffect(() => {
    heapTrack('Page Presented', {
      Page: 'Benefit List',
      Experiment: 'Passwordless Signup Flow Experiment 2',
      Variant: 'Control & Prep user for card ask',
      Section: 'Signup',
      MerchantHandle: merchant.handle,
    })
  }, [merchant])

  return (
    <Layout>
      <Header className="mb-0">
        <Translate
          value="pages.benefits.title"
          className="d-inline-block px-xl"
          merchant={merchant.name}
        />
      </Header>

      <Spacer size="m" />

      <div
        className="py-m px-s rounded mx-s"
        style={{
          backgroundColor: colors.primary50,
          color: colors.primary,
        }}
      >
        <div className="text-left body-text-4">
          <div className="align-top d-flex mb-m">
            <FontAwesomeIcon className="mt-1" icon={faCheck} size="sm" />
            <div className="d-inline-block ml-xs">
              <Translate value="pages.benefits.box.item1" className="d-block" />
            </div>
          </div>

          <div className="align-top d-flex mb-m">
            <FontAwesomeIcon className="mt-1" icon={faCheck} size="sm" />
            <div className="d-inline-block ml-xs">
              <Translate value="pages.benefits.box.item2" className="d-block" />
            </div>
          </div>
          <div className="align-top d-flex">
            <FontAwesomeIcon className="mt-1" icon={faCheck} size="sm" />
            <div className="d-inline-block ml-xs">
              <Translate value="pages.benefits.box.item3" className="d-block" />
            </div>
          </div>
        </div>
      </div>

      <Spacer size="l" />

      <Link
        to="../progress"
        className="mb-xs d-block text-decoration-none"
        onClick={() => {
          heapTrack('Button Click', {
            Page: 'Benefit List',
            Button: 'Continue',
            Experiment: 'Passwordless Signup Flow Experiment 2',
            Variant: 'Control & Prep user for card ask',
            MerchantHandle: merchant.handle,
          })
        }}
      >
        <Button className="w-100">
          <Translate value="pages.benefits.button" />
        </Button>
      </Link>
    </Layout>
  )
}

export default Benefits
