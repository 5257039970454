export default {
  form: {
    firstName: 'first name',
    lastName: 'last name',
    favoriteLocation: {
      label: 'preferred location',
      placeholder: 'Select one',
      help: 'Locations denoted with * are coming soon.',
    },
    phoneNumber: {
      label: 'phone number',
      legal:
        'Optional. Your phone number is only used to verify your account. Standard messaging rates may apply.',
      placeholder: '555-555-5555',
    },
    button: 'Next',
    errors: {
      generic: 'We had trouble updating your information. Please try again.',
      phone: 'That phone number is already registered.',
    },
  },
  skipButton: 'Do this later',
}
