// @flow
import React, { useEffect } from 'react'
import { Receipt } from 'assets/images/receipt.svg.js'
import { Gift } from 'assets/images/gift.svg.js'
import { Translate } from 'react-i18nify'
import styles from './Reward.module.scss'
import Header from 'components/Signup/Header'
import Layout from 'components/Signup/Layout'
import useTheme from 'hooks/useTheme'

import type { NavigateFn } from '@reach/router'
type Props = {
  navigate?: NavigateFn,
}

const Reward = ({ navigate }: Props) => {
  const { colors } = useTheme()

  useEffect(() => {
    const timer = setTimeout(() => {
      if (navigate) navigate('../track-progress')
    }, 3000)
    return () => clearTimeout(timer)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout>
      <Header>
        <Translate value="pages.reward.title" />
      </Header>

      <div className="px-xs mb-xxl" />
      <div className="position-relative">
        <div className={`position-absolute ${styles.container}`}>
          <div
            className={`position-relative rounded-circle ${styles.circle}`}
            style={{
              backgroundColor: colors.primary,
            }}
          />
        </div>
        <div className="align-bottom mb-xl">
          <Receipt className={styles.icon} color={colors.primary} />
          <Receipt className={styles.icon} color={colors.primary} />
          <Receipt className={styles.icon} color={colors.primary} />
          <Gift className={`${styles.icon} mb-xs`} color={colors.primary} />
        </div>
      </div>
    </Layout>
  )
}

export default Reward
