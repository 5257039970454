import React from 'react'
import { I18n } from 'react-i18nify'

export const LockIcon = ({ color, className }) => (
  <div style={{ paddingBottom: 5 }}>
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      title={I18n.t('pages.trackProgressBonus.lockIcon')}
      className={className}
    >
      <path
        d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z"
        fill={color}
      />
      <path
        d="M45.3723 26.3177H44.2833V23.1376C44.2833 16.9922 39.3107 12 33.1978 12H30.8022C24.6869 12 19.7167 16.9922 19.7167 23.1376V26.3177H18.6277C17.732 26.3177 17 27.0509 17 27.9533V46.3643C17 47.2668 17.732 48 18.6277 48H45.3723C46.268 48 47 47.2668 47 46.3643V27.9533C47 27.0509 46.268 26.3177 45.3723 26.3177ZM24.6033 23.1364C24.6033 19.7031 27.3809 16.9094 30.8022 16.9094H33.1978C36.6167 16.9094 39.3967 19.7031 39.3967 23.1364V26.3165H24.6033V23.1364ZM34.4361 38.2773L34.9675 43.0234H29.0313L29.5603 38.2773C28.6695 37.5489 28.0652 36.4844 28.0652 35.2472C28.0652 33.0655 29.8266 31.2906 31.9976 31.2906C34.171 31.2906 35.9324 33.0655 35.9324 35.2472C35.9336 36.4844 35.3294 37.5489 34.4361 38.2773Z"
        fill="white"
      />
      <defs></defs>
    </svg>
  </div>
)
