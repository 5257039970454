export default {
  title: 'Are you sure?',
  box: {
    title: 'Automatic sync benefits',
    item1: {
      title: 'More earned rewards',
      subtitle: 'No forgotten receipts',
    },
    item2: {
      title: 'Faster and easier',
      subtitle: 'No manual uploads required',
    },
    item3: {
      title: 'Earn',
      subtitle: 'When you enroll',
    },
  },
  buttons: {
    primary: 'Sync purchases with card',
    secondary: 'I’ll upload my receipts manually',
  },
}
