import { I18n } from 'react-i18nify'
import metadata from './metadata'
import allSet from './pages/allSet'
import app from './pages/app'
import choose from './pages/choose'
import confirmation from './pages/confirmation'
import congrats from './pages/congrats'
import creditCard from './pages/creditCard'
import info from './pages/info'
import notFound from './pages/notFound'
import passwordlessLogin from './pages/passwordlessLogin'
import passwordlessEmail from './pages/passwordlessEmail'
import receipt from './pages/receipt'
import reward from './pages/reward'
import trackProgress from './pages/trackProgress'
import trackProgressBonus from './pages/trackProgressBonus'
import sms from './pages/sms'
import welcome from './pages/welcome'
import benefits from './pages/benefits'
import progress from './pages/progress'

I18n.setTranslations({
  en: {
    errors: {
      network: 'We are having trouble communicating right now. Try again.',
    },
    metadata,
    contactSupport: {
      linkText: 'Contact Support',
      subjectApp: 'Question about my account (WL%{appId})',
      subject: 'Question about my account',
    },
    pages: {
      allSet,
      app,
      benefits,
      choose,
      confirmation,
      congrats,
      creditCard,
      info,
      notFound,
      passwordlessLogin,
      passwordlessEmail,
      progress,
      receipt,
      reward,
      trackProgress,
      trackProgressBonus,
      sms,
      welcome,
    },
    validations: {
      creditCard: 'Please enter a valid credit card number',
      supportedCreditCard: 'This credit card provider is unsupported',
      email: 'Please enter a valid email',
      required: 'This field is required',
      phone: 'Please enter a valid phone number, including the area code',
    },
  },
})

I18n.setLocale('en')
