import { addValidationRule } from 'formsy-react'
import isObject from 'lodash/isObject'
import isEmpty from 'lodash/isEmpty'
import cardValidator from 'card-validator'

addValidationRule('isRequired', (_values, value, enabled = true) => {
  if (!enabled) return true
  return isObject(value) ? !isEmpty(value) : !!value
})

addValidationRule('isCreditCard', (_values, value, enabled = true) => {
  if (!enabled || !value) return true
  const number = value.replace(/[^0-9]/, '')
  return cardValidator.number(number).isValid
})

addValidationRule('isSupportedCreditCard', (_values, value, enabled = true) => {
  if (!enabled || !value) return true
  const number = value.replace(/[^0-9]/, '')
  const card = cardValidator.number(number).card
  if (!card) return true

  const supportedCardTypes = ['visa', 'mastercard', 'american-express']
  return supportedCardTypes.includes(card.type)
})

addValidationRule('isPhone', (_values, value, enabled = true) => {
  if (!enabled || !value) return true
  const number = value.replace(/\D/g, '')
  return !!number.match(/^\d{10}$/)
})
