// @flow
import React, { useState } from 'react'
import { Link } from '@reach/router'
import { Button } from '@thanx/uikit/button'
import { Spacer } from '@thanx/uikit/spacer'
import { KIND } from 'baseui/notification'
import { useMutation } from 'react-apollo-hooks'
import { I18n, Translate } from 'react-i18nify'
import Header from 'components/Signup/Header'
import Layout from 'components/Signup/Layout'
import Notification from 'components/Signup/Notification'
import idx from 'idx'
import { useSignupData } from 'components/Signup/SignupData'
import { SMS_SUBSCRIBE_MUTATION } from './mutations'

import type { NavigateFn } from '@reach/router'

type PropsT = {
  navigate?: NavigateFn,
}

const Choose = ({ navigate }: PropsT) => {
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState(null)

  const { signupData } = useSignupData()
  const isSms = !!idx(signupData, _ => _.user.isSms)
  const phone = idx(signupData, _ => _.user.phone)
  const merchantId = idx(signupData, _ => _.merchant.id)
  const redeemDescription = idx(signupData, _ => _.program.redeem.description)

  const smsSubscribe = useMutation(SMS_SUBSCRIBE_MUTATION)

  async function onClick() {
    try {
      setIsLoading(true)

      await smsSubscribe({
        variables: {
          phone,
          merchantId,
          redeemDescription,
        },
      })

      setIsLoading(false)

      if (navigate) navigate('../confirmation')
    } catch (_) {
      setErrors(I18n.t('pages.choose.errors.sms'))
      setIsLoading(false)
    }
  }

  let smsLink = (
    <Link to="../sms" className="text-decoration-none">
      <Button className="w-100">
        <Translate value="pages.choose.smsButton.enable" />
      </Button>
    </Link>
  )
  if (isSms) {
    smsLink = (
      <Button className="w-100" isLoading={isLoading} onClick={onClick}>
        <Translate value="pages.choose.smsButton.continue" />
      </Button>
    )
  }

  const errorBanner = errors ? (
    <Notification kind={KIND.negative}>{errors}</Notification>
  ) : null

  return (
    <Layout open>
      <Header className="mb-0">
        <Translate
          value="pages.choose.title"
          tag="p"
          dangerousHTML
          className="px-xl mb-0"
        />
      </Header>

      <Spacer size="l" />

      {errorBanner}

      <Link to="../app" className="text-decoration-none">
        <Button className="w-100 mb-s">
          <Translate value="pages.choose.downloadButton" />
        </Button>
      </Link>

      <Translate
        value="pages.choose.or"
        tag="p"
        className="grey-50-text body-text-4"
      />

      {smsLink}
    </Layout>
  )
}

export default Choose
