export default {
  title: 'Bonus! ',
  title2: 'Unlock your first reward when you add your card',
  primaryBtn: {
    main: 'Add my card',
  },
  secondaryBtn: "I don't want my free reward",
  lockIcon: 'locked-icon',
  redeemText: 'Get a free reward!',
}
