// @flow
import React, { useState, useEffect } from 'react'
import { FormsyInput } from '@thanx/uikit/input'
import { Button } from '@thanx/uikit/button'
import { Spacer } from '@thanx/uikit/spacer'
import { Spinner } from '@thanx/uikit/spinner'
import Formsy from 'formsy-react'
import idx from 'idx'
import { Translate, I18n } from 'react-i18nify'
import poweredByThanx from 'assets/images/img_powerdByThanx.svg'
import Label from 'components/Label'
import ServerSideError from 'components/ServerSideError'
import Header from 'components/Signup/Header'
import Layout from 'components/Signup/Layout'
import {
  generatePrivacyPolicyUrl,
  generateTermsOfServiceUrl,
} from 'utilities/finePrint'
import styles from './Welcome.module.scss'

import type { MerchantQuery_merchant as MerchantT } from 'components/Signup/__generated__/MerchantQuery'
import type { ProgramQuery_merchant_program as ProgramT } from 'components/Signup/__generated__/ProgramQuery'

type PropsT = {
  merchant: MerchantT,
  program: ?ProgramT,

  error: ?string,
  headerI18n: ?string,
  isLoading: boolean,
  isSuccess: boolean,
  isPasswordless: boolean,

  // Form submission
  onSubmit({ email: string, password?: string }): any,
  onInvalid(): any,
  onValid(): any,
}

function View({
  merchant,
  program,
  error,
  headerI18n,
  isLoading,
  isSuccess,
  isPasswordless,
  onSubmit,
  onInvalid,
  onValid,
}: PropsT) {
  const [showForm, setShowForm] = useState<boolean>(false)

  // We want to make sure the js bundle loads before we show the form
  useEffect(() => {
    setShowForm(true)
  }, [])

  const appHandle =
    merchant.app && merchant.isBranded ? merchant.app.handle : ''

  let header = <Spacer size="xxxl" />
  if (program) {
    if (program.__typename === 'Invite') {
      header = (
        <Header className="mb-s">
          <div>
            <Translate
              className="normal"
              value="pages.welcome.title.invite"
              name={idx(program, _ => _.referrer.firstName)}
            />
          </div>
          <div>
            {program.redeem ? program.redeem.description : null}{' '}
            <Translate
              className="normal"
              value="pages.welcome.title.invite_need"
            />
          </div>
        </Header>
      )
    } else if (!headerI18n && idx(merchant, _ => _.app.strings.signupWelcome)) {
      header = (
        <Header className="mb-xxl">{merchant.app.strings.signupWelcome}</Header>
      )
    } else {
      header = (
        <Header className="mb-xxl">
          <Translate value={headerI18n} />
        </Header>
      )
    }
  }

  return (
    <Layout>
      <div className="d-flex flex-column justify-content-between h-100">
        <div>
          {header}

          <div className={`position-relative ${styles.formContainer} `}>
            {showForm ? null : (
              <div className={`position-absolute ${styles.spinner}`}>
                <Spinner className="w-100 h-100" />
              </div>
            )}

            <Formsy
              className={`mb-l ${styles.form} ${showForm ? '' : styles.hidden}`}
              onInvalid={onInvalid}
              onValid={onValid}
              onValidSubmit={onSubmit}
            >
              <FormsyInput
                name="email"
                className="mb-s text-left"
                label={<Label title={I18n.t('pages.welcome.inputs.email')} />}
                required
                type="email"
                validations={{
                  isEmail: true,
                  isRequired: true,
                }}
                validationErrors={{
                  isRequired: I18n.t('validations.required'),
                  isEmail: I18n.t('validations.email'),
                }}
              />
              {error ? <ServerSideError error={error} /> : null}
              {isPasswordless ? null : (
                <FormsyInput
                  name="password"
                  className="mb-m text-left"
                  label={
                    <Label
                      title={I18n.t('pages.welcome.inputs.password.label')}
                    />
                  }
                  required
                  validations={{
                    isRequired: true,
                    minLength: 6,
                  }}
                  type="password"
                  validationErrors={{
                    isRequired: I18n.t('validations.required'),
                    minLength: I18n.t(
                      'pages.welcome.inputs.password.validation',
                      {
                        count: 6,
                      }
                    ),
                  }}
                />
              )}
              <Button
                className="w-100"
                isLoading={isLoading}
                disabled={false}
                isSuccess={isSuccess}
                type="submit"
              >
                <Translate value="pages.welcome.button" />
              </Button>
            </Formsy>
          </div>
        </div>

        <div>
          <Translate
            value="pages.welcome.legal"
            className="body-text-6 grey-50-text mb-s px-m"
            tag="p"
            dangerousHTML
            privacyPolicyUrl={generatePrivacyPolicyUrl(appHandle)}
            termsOfServiceUrl={generateTermsOfServiceUrl(appHandle)}
          />
          <img
            src={poweredByThanx}
            alt={I18n.t('pages.welcome.poweredByThanxAlt')}
          />
        </div>
      </div>
    </Layout>
  )
}

export default View
