// @flow
import idx from 'idx'
import { useSignupData } from 'components/Signup/SignupData'

// Return the program with the reward that the user will get when they
// enroll their card.  Only intro programs use their own reward. Other
// programs use the intro reward.
// See https://thanxapp.atlassian.net/browse/BUG-855
export function useEnrollCardProgram() {
  const { signupData } = useSignupData()

  const earnType = idx(signupData, _ => _.program.earn.type)
  const introProgram =
    earnType && earnType.includes('Program::Earn::Intro')
      ? signupData.program
      : signupData.merchant.introProgram

  return introProgram
}
