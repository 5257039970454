// @flow
import React, { StrictMode } from 'react'
import { Redirect, Router } from '@reach/router'
import { ThemeProvider } from '@thanx/uikit/StyleProvider'
import { createTheme } from '@thanx/uikit/theme'
import { useSignupData } from 'components/Signup/SignupData'
import Merchant from './Merchant'
import NotFound from './NotFound'
import PasswordlessLanding from './PasswordlessLanding'
import Program from './Program'
import Screens from './Screens'
import SmsAccount from './SmsAccount'

const App = () => {
  const { signupData } = useSignupData()

  let theme
  if (signupData.merchant) {
    const themeArgs = { primary: signupData.merchant.app.primaryColor }
    theme = createTheme(themeArgs)
  } else {
    theme = createTheme()
  }

  return (
    <StrictMode>
      <ThemeProvider theme={theme}>
        <Router>
          <NotFound path="/" />
          <SmsAccount path="txt/:uid" />
          <PasswordlessLanding path="/magic" />
          {/* $FlowFixMe: https://github.com/gatsbyjs/gatsby/issues/8047 */}
          <Redirect
            path=":merchantHandle"
            from=":merchantHandle"
            to=":merchantHandle/join"
            noThrow
          />
          <Merchant path=":merchantHandle">
            <Program path=":programIdentifier">
              {/* $FlowIgnore */}
              <Screens path="*" />
            </Program>
          </Merchant>
        </Router>
      </ThemeProvider>
    </StrictMode>
  )
}

export default App
