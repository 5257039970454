// @flow
import { gql } from 'apollo-boost'

export const USER_BY_UID_QUERY = gql`
  query UserByUid($uid: String!) {
    userByUid(uid: $uid) {
      isSms
      signupMerchant {
        handle
      }
    }
  }
`
