// @flow
import React from 'react'

type PropsT = {
  title: string,
}

const Label = (props: PropsT) => {
  return <span className="small-caps-3 grey-50">{props.title}</span>
}

export default Label
