// @flow
import React from 'react'
import Header from 'components/Signup/Header'
import { Translate } from 'react-i18nify'
import { Spacer } from '@thanx/uikit/spacer'
import { Link } from '@reach/router'
import { Button } from '@thanx/uikit/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import Layout from 'components/Signup/Layout'
import useTheme from 'hooks/useTheme'
import idx from 'idx'
import { useSignupData } from 'components/Signup/SignupData'

const Receipt = () => {
  const { colors } = useTheme()
  const { signupData } = useSignupData()

  return (
    <Layout>
      <Header className="mb-0">
        <Translate
          value="pages.receipt.title"
          className="d-inline-block px-xl"
        />
      </Header>

      <Spacer size="m" />

      <div
        className="py-m px-s rounded mx-s"
        style={{
          backgroundColor: colors.primary50,
          color: colors.primary,
        }}
      >
        <Translate
          value="pages.receipt.box.title"
          className="small-caps-3 mb-m"
          tag="p"
        />
        <div className="text-left body-text-4">
          <div className="align-top d-flex mb-m">
            <FontAwesomeIcon className="mt-1" icon={faCheck} size="sm" />
            <div className="d-inline-block ml-xs">
              <Translate
                value="pages.receipt.box.item1.title"
                className="d-block bold"
              />
              <Translate
                value="pages.receipt.box.item1.subtitle"
                className="d-block"
              />
            </div>
          </div>

          <div className="align-top d-flex mb-m">
            <FontAwesomeIcon className="mt-1" icon={faCheck} size="sm" />
            <div className="d-inline-block ml-xs">
              <Translate
                value="pages.receipt.box.item2.title"
                className="d-block bold"
              />
              <Translate
                value="pages.receipt.box.item2.subtitle"
                className="d-block"
              />
            </div>
          </div>

          <div className="align-top d-flex">
            <FontAwesomeIcon className="mt-1" icon={faCheck} size="sm" />
            <div className="d-inline-block ml-xs">
              <div className="d-flex flex-row d-block bold">
                <Translate value="pages.receipt.box.item3.title" />
                &nbsp;
                {idx(signupData, _ => _.program.redeem.description) || (
                  <Translate value="pages.trackProgress.redeemText" />
                )}
              </div>
              <Translate
                value="pages.receipt.box.item3.subtitle"
                className="d-block"
              />
            </div>
          </div>
        </div>
      </div>

      <Spacer size="l" />

      <Link
        to={'../credit-card'}
        className="mb-xs d-block text-decoration-none"
      >
        <Button className="w-100">
          <Translate value="pages.receipt.buttons.primary" />
        </Button>
      </Link>

      <Link to="../info" className="text-decoration-none">
        <Button kind="minimal_secondary" className="pb-0">
          <Translate value="pages.receipt.buttons.secondary" />
        </Button>
      </Link>
    </Layout>
  )
}

export default Receipt
