// @flow
import { useContext } from 'react'
import { ThemeContext } from 'baseui/styles/theme-provider'

export default function() {
  const context = useContext(ThemeContext)

  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeContext.Provider')
  }

  return context
}
