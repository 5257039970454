// @flow
import React, { useState, useEffect } from 'react'
import { FormsyInput } from '@thanx/uikit/input'
import { formatCreditCard } from '@thanx/uikit/input/formatters'
import { Button } from '@thanx/uikit/button'
import { KIND } from 'baseui/notification'
import cardValidator from 'card-validator'
import Formsy from 'formsy-react'
import idx from 'idx'
import { I18n, Translate } from 'react-i18nify'
import Label from 'components/Label'
import Layout from 'components/Signup/Layout'
import BackButton from 'components/Signup/BackButton'
import Header from 'components/Signup/Header'
import FinePrint from 'components/Signup/FinePrint'
import Notification from 'components/Signup/Notification'
import ContactSupport from 'components/Signup/ContactSupport'
import { useSignupData } from 'components/Signup/SignupData'
import {
  generatePrivacyPolicyUrl,
  generateTermsOfServiceUrl,
} from 'utilities/finePrint'
import { getDigits } from 'utilities/formHelpers'
import { useEnrollCardProgram } from 'utilities/program'
import { getCardIcon, getCardTypeFromCardValidatorType } from './helpers'
import { useRegisterCard } from './cardHelpers'

import type { NavigateFn } from '@reach/router'

type PropsT = {
  navigate?: NavigateFn,
}

export const RegistrationState = {
  INACTIVE: 'inactive',
  ACTIVE: 'active',
  SUCCESS: 'complete',
  ERRORED: 'errored',
}

const CreditCard = ({ navigate }: PropsT) => {
  const [cardType, setCardType] = useState(null)
  const [registrationState, setRegistrationState] = useState(
    RegistrationState.INACTIVE
  )
  const [errors, setErrors] = useState(null)

  const { signupData } = useSignupData()
  const offerProgram = useEnrollCardProgram()

  const registerCard = useRegisterCard()

  const { name, app } = signupData.merchant
  const { program } = signupData
  const appHandle = app && app.handle
  const appId = app && app.id
  const userId = idx(signupData, _ => _.user.id)
  const redeemText = idx(offerProgram, _ => _.redeem.description)
  const isCardRequired = idx(program, _ => _.isCardRequired)

  const cardFormPath = isCardRequired
    ? '../track-progress-bonus'
    : '../track-progress'

  useEffect(() => {
    let timer
    if (registrationState === RegistrationState.SUCCESS) {
      timer = setTimeout(() => {
        if (navigate)
          navigate(
            '../info',
            // $FlowFixMe
            { state: { fromCreditCard: true } }
          )
        setTimeout(() => setRegistrationState(RegistrationState.INACTIVE), 50)
      }, 750)
    }
    return () => clearTimeout(timer)
  }, [registrationState, setRegistrationState]) // eslint-disable-line react-hooks/exhaustive-deps

  const onFormChange = ({ cardNumber }) => {
    const { card } = cardValidator.number(cardNumber)
    if (!card) {
      setCardType(null)
    } else {
      setCardType(getCardTypeFromCardValidatorType(card.type))
    }
  }

  const cardIcon = getCardIcon(cardType)

  const onValidSubmit = async ({ cardNumber }) => {
    setRegistrationState(RegistrationState.ACTIVE)

    const result = await registerCard({ cardNumber })

    setRegistrationState(result.registrationState)
    if (result.error) {
      setErrors(result.error)
    }
  }

  const errorBanner = errors ? (
    <Notification kind={KIND.negative}>{errors}</Notification>
  ) : null

  return (
    <Layout>
      <BackButton to={cardFormPath} />

      <Header className="mt-s mb-l">
        {redeemText ? (
          <Translate value="pages.creditCard.title" redeemText={redeemText} />
        ) : (
          <Translate value="pages.creditCard.altTitle" />
        )}
      </Header>

      <div className="text-left mb-l">
        {errorBanner}
        <Formsy
          mapping={inputs => ({
            ...inputs,
            cardNumber: getDigits(inputs.cardNumber || ''),
          })}
          onChange={onFormChange}
          onValidSubmit={onValidSubmit}
        >
          <FormsyInput
            className="mb-s"
            name="cardNumber"
            label={
              <Label title={I18n.t('pages.creditCard.numberInput.label')} />
            }
            overrides={{
              After: () => cardIcon,
            }}
            type="tel"
            placeholder="0000 0000 0000 0000"
            format={formatCreditCard}
            validations={{
              isRequired: true,
              isCreditCard: true,
              isSupportedCreditCard: true,
            }}
            validationErrors={{
              isRequired: I18n.t('validations.required'),
              isCreditCard: I18n.t('validations.creditCard'),
              isSupportedCreditCard: I18n.t('validations.supportedCreditCard'),
            }}
            value=""
          />

          <FinePrint className="text-center mb-s">
            <span className="grey-70-text">
              <Translate
                value="pages.creditCard.finePrint.transactionSharing"
                merchant={name}
                privacyPolicyUrl={generatePrivacyPolicyUrl(appHandle)}
                termsOfServiceUrl={generateTermsOfServiceUrl(appHandle)}
                dangerousHTML
              />
            </span>
          </FinePrint>

          <Button
            className="w-100"
            type="submit"
            isLoading={registrationState === RegistrationState.ACTIVE}
            isSuccess={registrationState === RegistrationState.SUCCESS}
          >
            <Translate value="pages.creditCard.button" />
          </Button>
        </Formsy>
      </div>

      <ContactSupport appId={appId} userId={userId} />
    </Layout>
  )
}

export default CreditCard
