import { I18n } from 'react-i18nify'
import { useSignupData } from 'components/Signup/SignupData'
import { getToken } from 'utilities/token'
import fetch from 'isomorphic-fetch'
import { RegistrationState } from './index'

/*
 * Use the Basis Theory proxy endpoint to register cards
 */
export function useRegisterCard() {
  const { signupData } = useSignupData()
  const clientId = signupData.merchant.app.oauthClientId

  /*
   * @param cardData ({ cardNumber: string })
   * @return { registrationState: RegistrationState, error?: string }
   */
  return async cardData => {
    const { cardNumber } = cardData
    const token = getToken()

    const response = await fetch(`${process.env.GATSBY_SECURE_API_URL}/cards`, {
      method: 'POST',
      headers: {
        'Accept-Version': '4.0',
        'Content-Type': 'application/json',
        'X-ClientId': clientId,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        card: {
          number: cardNumber,
        },
      }),
    })

    if (!response.ok) {
      return {
        registrationState: RegistrationState.ERRORED,
        error: I18n.t('pages.creditCard.errors.generic'),
      }
    }

    return { registrationState: RegistrationState.SUCCESS }
  }
}
