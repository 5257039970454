// @flow
import React from 'react'
import { I18n } from 'react-i18nify'
import { FormsyInput } from '@thanx/uikit/input'
import { formatPhone } from '@thanx/uikit/input/formatters'

const PhoneInput = (props: React$ElementConfig<typeof FormsyInput>) => {
  const validations = props.validations || {}
  const validationErrors = props.validationErrors || {}

  return (
    <FormsyInput
      format={formatPhone}
      type="tel"
      validations={{
        isPhone: true,
        ...validations,
      }}
      validationErrors={{
        isPhone: I18n.t('validations.phone'),
        ...validationErrors,
      }}
      {...props}
    />
  )
}

export default PhoneInput
