// @flow
import React from 'react'
import { graphql } from 'gatsby'
import { SignupDataProvider } from './SignupData'
import App from './App'
import { CustomApolloProvider } from 'clients/apollo/consumer'
import 'assets/stylesheets/index.scss'
import 'utilities/formsyValidators'
import 'locales'
import 'typeface-lato'
import LaunchDarklyProvider from '../LaunchDarklyProvider'

import type { MerchantQueryT } from './types'

type PropsT = {
  data: {
    thanxapi: MerchantQueryT,
  },
}

// Fragments defined in ./queries.js
export const query = graphql`
  query MerchantQuery(
    $handle: String!
    $programIdentifier: String!
    $shouldQueryProgram: Boolean!
  ) {
    thanxapi {
      merchant(handle: $handle) {
        ...MerchantFields
        program(identifier: $programIdentifier)
          @include(if: $shouldQueryProgram) {
          ...ProgramFields
        }
      }
    }
  }
`

const Signup = ({ data }: PropsT) => {
  let signupData = {}
  if (data) {
    const { merchant } = data.thanxapi
    signupData.merchant = merchant

    if (merchant.program) {
      signupData.program = merchant.program
    }
  }

  return (
    <SignupDataProvider initialValue={signupData}>
      <CustomApolloProvider
        merchantHandle={data ? data.thanxapi.merchant.handle : undefined}
        appId={data ? data.thanxapi.merchant.app.id : undefined}
      >
        <LaunchDarklyProvider
          merchantHandle={data ? data.thanxapi.merchant.handle : undefined}
        >
          <App />
        </LaunchDarklyProvider>
      </CustomApolloProvider>
    </SignupDataProvider>
  )
}

export default Signup
