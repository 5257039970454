// @flow
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Helmet from 'react-helmet'
import { I18n } from 'react-i18nify'
import { usePathSegments } from 'components/Signup/PathSegments'
import { useSignupData } from 'components/Signup/SignupData'

const METADATA_QUERY = graphql`
  query SEOMetadataQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const SEO = () => {
  const {
    site: {
      siteMetadata: { siteUrl: baseUrl },
    },
  } = useStaticQuery(METADATA_QUERY)
  const {
    signupData: { merchant },
  } = useSignupData()
  const { merchantHandle } = usePathSegments()

  const app = merchant.app

  const title = merchant.isBranded ? `${app.name} Rewards` : app.name

  return (
    <Helmet>
      <title>
        {I18n.t('metadata.title', {
          name: merchant.isBranded ? app.name : merchant.name,
        })}
      </title>

      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={title} />
      <meta
        property="og:description"
        content={
          merchant.isBranded
            ? I18n.t('metadata.description.whitelabel', {
                merchantName: merchant.name,
                appName: app.name,
              })
            : I18n.t('metadata.description.default', {
                merchantName: merchant.name,
              })
        }
      />
      <meta property="og:image" content={merchant.logoImage} />
      <meta property="og:url" content={`${baseUrl}/${merchantHandle}`} />

      {/* This should be covered by og:image but it's not working */}
      <meta property="twitter:image" content={merchant.logoImage} />
    </Helmet>
  )
}

export default SEO
