// @flow
import React, { useEffect } from 'react'
import { useApolloClient } from 'react-apollo-hooks'
import { useSignupData } from 'components/Signup/SignupData'
import { isLoggedIn } from 'utilities/auth'
import { USER_QUERY } from 'components/Signup/queries'

import type { NavigateFn } from '@reach/router'
import type { ComponentType } from 'react'

type Props = {
  merchantHandle?: string,
  programIdentifier?: string,
  component: ComponentType<*>,
  navigate?: NavigateFn,
}

const PrivateRoute = (props: Props) => {
  const { merchantHandle, programIdentifier, navigate } = props

  const client = useApolloClient()
  const { signupData, setSignupData } = useSignupData()
  const user = signupData.user

  useEffect(() => {
    let mainPage = '/'
    if (merchantHandle && programIdentifier) {
      mainPage = '/' + merchantHandle + '/' + programIdentifier
    }

    async function fetchUser() {
      try {
        const { data } = await client.query({ query: USER_QUERY })

        setSignupData(state => ({
          ...state,
          user: data.user,
        }))
      } catch {
        if (navigate) navigate(mainPage)
      }
    }

    const notLoginPage = location.pathname !== mainPage
    if (!isLoggedIn() && notLoginPage) {
      if (navigate) navigate(mainPage)
      return
    }

    if (!user) {
      fetchUser()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantHandle, programIdentifier, setSignupData, user, client])

  const { component: Component, ...rest } = props

  return <Component {...rest} />
}

export default PrivateRoute
