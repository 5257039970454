// @flow
import React from 'react'
import ApolloClient from 'apollo-boost'
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { InMemoryCache } from 'apollo-cache-inmemory'
import fetch from 'isomorphic-fetch'
import { ApolloProvider } from 'react-apollo'
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks'
import { buildAuthorizationHeader, getToken } from 'utilities/token'
import fragmentTypes from 'graphql/fragmentTypes.json'
import ahoy from 'ahoy.js'

const CONSUMER_API_URL =
  process.env.GATSBY_CONSUMER_API_URL ||
  'http://localhost:1000/you-should-set-GATSBY_CONSUMER_API_URL-in-env/'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: fragmentTypes,
})

const cache = new InMemoryCache({
  fragmentMatcher,
})

type BuildOptions = {
  merchantHandle?: string,
  appId?: number | string,
}
export function buildApolloClient(options: BuildOptions = {}) {
  const { merchantHandle, appId } = options

  const baseHeaders = {
    'Thanx-Merchant': merchantHandle,
    'Thanx-App': appId,
  }

  return new ApolloClient({
    uri: `${CONSUMER_API_URL}/graphql`,
    fetch,
    request: operation => {
      let headers: Object = {
        ...baseHeaders,
        'Ahoy-Visit': ahoy.getVisitId(),
        'Ahoy-Visitor': ahoy.getVisitorId(),
      }
      const token = getToken()
      if (token) {
        headers['Authorization'] = buildAuthorizationHeader(token)
      }

      operation.setContext({
        headers: headers,
      })
    },
    cache,
  })
}

// ApolloProvider with the default client
type Props = {
  children: React$Node,
  merchantHandle?: string,
  appId?: number | string,
}
export const CustomApolloProvider = (props: Props) => {
  const { children, appId, merchantHandle } = props

  const client = buildApolloClient({ merchantHandle, appId })

  return (
    <ApolloProvider client={client}>
      <ApolloHooksProvider client={client}>{children}</ApolloHooksProvider>
    </ApolloProvider>
  )
}
