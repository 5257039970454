// @flow
import { gql } from 'apollo-boost'

export const SMS_SUBSCRIBE_MUTATION = gql`
  mutation smsSubscribe(
    $phone: String!
    $merchantId: ID!
    $redeemDescription: String!
  ) {
    smsSubscribe(
      phone: $phone
      merchantId: $merchantId
      redeemDescription: $redeemDescription
    ) {
      void
    }
  }
`
