// @flow
import React, { useEffect } from 'react'
import * as queryString from 'query-string'
import idx from 'idx'
import { navigate } from 'gatsby'
import { useApolloClient } from 'react-apollo-hooks'
import { USER_BY_UID_QUERY } from './queries'
import isEmpty from 'lodash/isEmpty'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

import type { UserByUid } from './__generated__/UserByUid'

type PropsT = {
  location?: {
    search?: string,
  },
  uid?: string,
}

const SmsAccount = (props: PropsT) => {
  const client = useApolloClient()
  // looks like ?handle=tigerexpress&signup_code=SC&utm_source=UTMS&utm_content=UTMC
  const search = idx(props, _ => _.location.search)
  let queryParams = search ? queryString.parse(search) : {}
  useEffect(() => {
    if (!props.uid) {
      navigate('/')
      return
    }
    async function fetchUser() {
      try {
        const {
          data,
          errors,
        }: {
          data: UserByUid,
          errors: Object,
        } = await client.query({
          query: USER_BY_UID_QUERY,
          variables: {
            uid: props.uid,
          },
        })

        if (!isEmpty(errors) || !data.userByUid) {
          navigate('/')
          return null
        }

        const handle =
          idx(data.userByUid, _ => _.signupMerchant.handle) ||
          queryParams.handle

        if (!handle) {
          navigate('/')
          return null
        }

        delete queryParams.handle

        navigate(`/${handle}/join?${queryString.stringify(queryParams)}`, {
          state: { uid: props.uid },
          replace: true,
        })
      } catch (response) {
        navigate('/')
      }
    }

    fetchUser()
  }, [queryParams]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="text-center mt-xxl grey-50-text">
      <FontAwesomeIcon icon={faCircleNotch} spin size="3x" />
    </div>
  )
}

export default SmsAccount
